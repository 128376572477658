<template>
  <div ref="fabricContainer" class="relative w-full h-full" @resize="handleResize">
    <div ref="fabricCanvas" class="fabric-canvas w-full absolute top-1/2 -translate-y-1/2">
      <canvas :id="canvasId"></canvas>
    </div>
    <vanDialog></vanDialog>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { initAligningGuidelines } from "@/utils/fabric/aligning_guidelines.js";
import "fabric-history";
import _ from "lodash";

import controls from "@/utils/fabric/controls.js";
import { Dialog, Toast } from "vant";
import spec2 from "@/assets/images/icons/spec2.svg";
import { postExportCanvas, saveUserElements, uploadOSS } from "@/api/app";
import { dataURLtoBlob } from "@/utils/utils";

import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "EditorCanvas",
  props: {
    canvasId: {
      type: String,
      default: "front",
    },
    perspective: {
      type: String,
      default: "front",
    },
  },
  data() {
    return {
      carrier: "",
      isZoomIn: false, //是否放大了
      boundOriginWidth: 35, //框原始大小
      boundRatio: 43 / 35, //框的比例
      boundCenterX: 0, //框初始xy坐标
      boundCenterY: 0,
      batchId: null, //画笔批次
      svgOffset: 0, //svg偏移量
      templateLoaded: false, //模板是否已经加载好
    };
  },
  watch: {},
  methods: {
    async init() {
      this.mcId && this.perspective === "front" && (await this.fetchMCConfig());
      // TODO: 添加触发条件
      this.initFabric();
      await this.initCanvas();
      this.initEvents();

      this.SET_OBJECT_IS_EMPTY({ ...this.objectIsEmpty, [this.perspective]: true });

      if (this.$route.query.fromAvatar) {
        const options = { fromAvatar: true, ...this.getAvatarColor, type: 1 };
        if (this.perspective === "front") this.addSvg({ url: this.getAvatarSvg, options });
      }
    },
    ...mapMutations([
      "SET_COLOR_CONFIG",
      "SET_SLIDER_VAL",
      "SET_OUTPUT_CANVAS_IMAGE",
      "SET_OUTPUT_PREVIEW_IMAGE",
      "SET_ACTIVE_COLOR_KEY",
      "SET_ACTIVE_COLOR",
      "SET_LEFT_SLEEVE_IMAGE",
      "SET_RIGHT_SLEEVE_IMAGE",
      "SET_OUTPUT_CANVAS_IMAGES",
      "SET_OUTPUT_PREVIEW_IMAGES",
      "SET_DESIGN_JSONS",
      "SET_ELEMENT_IDS",
      "SET_IP_IDS",
      "SET_CURRENT_IP_ID",
      "SET_ZOOM_TOGGLE",
      "SET_STICKER_INFO",
      "SET_OBJECT_IS_EMPTY",
      "SET_IS_DRAWING_MODE",
      "CHANGE_IS_INTERCEPT",
      "CHANGE_MENU_STATUS",
      "SET_UPLOADED_IMAGE",
    ]),
    ...mapActions(["fetchMCConfig"]),
    initFabric() {
      //初始化 fabric 设置属性
      fabric.perfLimitSizeTotal = fabric.perfLimitSizeTotal * 2; // 缓存大小
      fabric.maxCacheSideLimit = fabric.maxCacheSideLimit * 2; // 缓存大小限制
      fabric.forceGLPutImageData = true; // 使用显卡渲染
      fabric.Object.prototype.originX = fabric.Object.prototype.originY = "center"; // 设置所有元素对称点是center
      fabric.Object.prototype.transparentCorners = false; // 所有的控制点都不要半透明
      fabric.Object.prototype.toObject = (function (toObject) {
        // 将自定义数据再次复原在Object上面
        return function (properties) {
          return fabric.util.object.extend(toObject.call(this, properties), {
            // minScaleLimit: this.minScaleLimit || 0,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            url: this.url || "",
            typeId: this.typeId || "",
            colorKey: this.colorKey || "",
            ipId: this.ipId || "",
            elementId: this.elementId || "",
            defaultId: this.defaultId || "",
            isArtist: this.isArtist || false,
            isCarrierClass: this.isCarrierClass || false,
            isAvatar: this.isAvatar || false,
            colorConfig: this.colorConfig || {},
            batchId: this.batchId || "",
          });
        };
      })(fabric.Object.prototype.toObject);
      fabric.Textbox.prototype.editable = false;
      fabric.Canvas.prototype.clearRedo = function () {
        this.historyRedo = [];
      };
      fabric.Canvas.prototype.goOnHistory = function () {
        this.historyProcessing = false;
      };
    },
    async initCanvas() {
      this.canvas = new fabric.Canvas(this.canvasId, {
        allowTouchScrolling: true,
        preserveObjectStacking: true,
      });

      // initCenteringGuidelines(this.canvas);
      this.channel !== "admin" && initAligningGuidelines(this.canvas); //如果在中台,不显示辅助线
      this.canvas.offHistory();

      this.resizeCanvas();
      this.canvas.controlsAboveOverlay = true;

      this.initBus();
      await this.initCarrier();
      await this.initBound();
      await this.initOverlay();

      (this.perspective === "front" || this.perspective === "back") && this.initSleevesClipPath();
      !this.mcId && this.perspective === "front" && (await this.initBadgeArea());

      controls.renderControls(this.canvas, this.perspective);
      this.canvas.controlsAboveOverlay = true;

      this.setBrushSize(2);
      this.SET_ACTIVE_COLOR(this.getColorLibrary[0].color);
      this.setBrushColor(this.getColorLibrary[0].color);

      console.log(this.templateId, this.productId);
      // 载入半定制设计
      (this.templateId || this.productId) && this.loadFromTemplate();
      this.resetTemplateObjects();

      if (this.mcId && this.perspective === "front") await this.initMCConfig();

      this.zoom150x();

      this.canvas.onHistory();
      this.canvas.historyUndo = [];
      this.canvas.historyRedo = [];

      this.canvas.renderAll();
    },
    resetTemplateObjects() {
      this.canvas.getObjects().forEach(o => {
        if (o.defaultId) {
          return;
        }
        o.clipPath = this.clipPath;

        if (o.typeId == 1) {
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }
      });
    },

    /*
     * 1. bound
     */
    async loadFromTemplate(force = false) {
      console.log(force);
      if (this.templateLoaded && !force) return; //通过templateId参数来进入二次定制, 进入就调用loadFromTemplate, force是方便模板模块反复调用的

      if (!this.designJSON[this.perspective]) return; // 通过templateId参数来进入二次定制,这面没有就不调用

      Toast.loading({
        message: "加载模板中...",
        forbidClick: true,
        duration: 0,
      });

      this.canvas.getObjects().forEach(o => {
        //清除之前的objects
        !o.isCarrierClass && this.canvas.remove(o);
      });

      const { objects: designJSONObjects, colorConfig } = JSON.parse(this.designJSON[this.perspective]); //拿到接口传回来的designJSON
      console.log(designJSONObjects);

      // TODO: 待优化字体 目前是加载全部字体 以后要遍历设计的字体来按需加载
      const fontPromise = [];
      this.fontList.forEach(({ font_family: fontFamily, value: url }) => {
        const font = new FontFace(fontFamily, `url(${url})`);
        fontPromise.push(
          new Promise(resolve => {
            font
              .load()
              .then(() => {
                document.fonts.add(font);
                resolve();
              })
              .catch(() => {
                resolve("font load error");
              });
          }),
        );
      });

      await Promise.allSettled(fontPromise);

      const { boundX, boundY, carrierWidth } = this.carrierConfig[this.perspective];

      //template center
      const templateLeft = designJSONObjects[0].left;
      const templateTop = designJSONObjects[0].top;
      const centerX = this.centerX + (boundX / carrierWidth) * this.canvas.width;
      const centerY = this.centerY + (boundY / carrierWidth) * this.canvas.height;
      const templateScale = centerX / templateLeft; //通过画布中点left值来算出当前载体应该放大多少倍
      const templateOffsetX = centerX - templateLeft;
      const templateOffsetY = centerY - templateTop;
      delete colorConfig.defaultColor;

      this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...colorConfig });

      new Promise(resolve => {
        fabric.util.enlivenObjects(designJSONObjects, async objects => {
          //给所有的Objects调整controls
          objects.forEach(async o => {
            if (o.defaultId === "bound") {
              o.defaultId = "tempBound";
              o.opacity = 0;
              o.isCarrierClass = false;
              this.tempBound = o;
              this.canvas.add(o);
              return;
            }
            if (o.isCarrierClass && o.defaultId !== "bound") {
              return;
            }

            // o.scaleX = o.scaleX * templateScale;
            // o.scaleY = o.scaleY * templateScale;
            // o.left = o.left * templateScale;
            // o.top = o.top + templateOffsetY ;
            o.clipPath = this.clipPath;
            this.canvas.add(o);
            if (o.typeId == 1 || o.type == "group") {
              o.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                tr: false,
                editControl: false,
              });
            }
          });
          resolve();
        });
      }).then(() => {
        const group = this.canvas.getObjects().filter(o => !o.isCarrierClass); //将非载体的初始元素编成一组

        const selectedObject = new fabric.ActiveSelection(group, {
          canvas: this.canvas,
        });

        // const selectedObjectLeft = (selectedObject.left - templateLeft) * templateScale + this.canvas.width / 2; //位置根据之前的left进行相减得出偏移量
        // const selectedObjectTop = (selectedObject.top - templateTop) * templateScale + this.canvas.height / 2; //位置根据之前的top进行相减得出偏移量

        // console.log({ selectedObjectLeft, selectedObjectTop });

        // this.canvas.setActiveObject(selectedObject);
        // this.canvas.centerObject(selectedObject);

        // console.log(templateScale);

        // selectedObject.set({
        //   left: selectedObjectLeft,
        //   top: selectedObjectTop,
        // });
        // selectedObject.set({
        //   scaleX: selectedObject.scaleX * templateScale,
        //   scaleY: selectedObject.scaleY * templateScale,
        // });

        this.canvas.setActiveObject(selectedObject);
        this.canvas.centerObject(selectedObject);

        selectedObject.set({
          scaleX: selectedObject.scaleX * (this.bound.width / this.tempBound.width),
          scaleY: selectedObject.scaleY * (this.bound.width / this.tempBound.width),
        });

        const originClipOffsetX = (boundX / carrierWidth) * this.canvas.width;
        const originClipOffsetY = (boundY / carrierWidth) * this.canvas.width;

        selectedObject.set({
          left: this.canvas.width / 2 + originClipOffsetX,
          top: this.canvas.height / 2 + originClipOffsetY,
        });

        this.canvas.discardActiveObject();
        this.canvas
          .getObjects()
          .filter(o => o.defaultId === "tempBound")
          .forEach(o => {
            this.canvas.remove(o);
          });
        this.canvas.renderAll();
        Toast.clear();
      });
      console.log(this.canvas);
      this.templateLoaded = true;
    },

    async initCarrier() {
      this.carrier = this.carrierConfig[this.perspective].carrierImage;
      await this.addImage(this.carrier, { selectable: false });
      this.resetInitialObjects();
      this.canvas.renderAll();
    },
    async initOverlay() {
      const { overlayImage } = this.carrierConfig[this.perspective];

      if (!overlayImage) return;

      let url = overlayImage;

      // TODO: 中台配置接入

      await new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          async img => {
            img.set({
              left: this.centerX,
              top: this.centerY,
              originX: "center",
              originY: "center",
              scaleX: (this.bound.width / img.width).toFixed(2),
              scaleY: (this.bound.width / img.width).toFixed(2),
              backgroundColor: "transparent",
              selectable: false,
              defaultId: "overlayImage",
              isCarrierClass: true,
              opacity: 0.8,
            });
            //浮层的图片
            this.canvas.setOverlayImage(img, this.canvas.renderAll.bind(this.canvas));

            resolve();
          },
          { crossOrigin: "Anonymous" },
        ),
      );
    },
    initSleevesClipPath() {
      const { leftX, leftY, leftWidth, leftHeight, leftAngle, rightX, rightY, rightWidth, rightHeight, rightAngle, carrierWidth } = this.carrierConfig[this.perspective];
      let leftOriginX, rightOriginX;
      if (this.perspective === "front") {
        leftOriginX = "right";
        rightOriginX = "left";
      } else {
        leftOriginX = "left";
        rightOriginX = "right";
      }
      this.leftSleeveClipPath = new fabric.Rect({
        left: (leftX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.1,
        top: (leftY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.1,
        originX: leftOriginX,
        originY: "center",
        width: (((leftWidth * this.canvas.getZoom()) / carrierWidth) * this.canvas.width) / 2,
        height: ((leftHeight * this.canvas.getZoom()) / carrierWidth) * this.canvas.width,
        absolutePositioned: true,
        angle: leftAngle,
      });
      this.rightSleeveClipPath = new fabric.Rect({
        left: (rightX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.1,
        top: (rightY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.1,
        originX: rightOriginX,
        originY: "center",
        width: (((rightWidth * this.canvas.getZoom()) / carrierWidth) * this.canvas.width) / 2,
        height: ((rightHeight * this.canvas.getZoom()) / carrierWidth) * this.canvas.width,
        absolutePositioned: true,
        angle: rightAngle,
      });
    },

    // 初始化框 2023年2月1日17:10:08
    async initBound() {
      // console.log("object", this.canvas.getObjects());

      let { boundX, boundY, boundWidth, boundHeight, carrierWidth, clipRadius } = this.carrierConfig[this.perspective]; // 这里的单位是cm
      let x, y, width, height;
      if (this.mcId && this.perspective === "front") {
        const { region_x, region_y, region_width, region_height } = this.mcConfig.design_region[0];
        x = region_x;
        y = region_y;
        width = region_width;
        height = region_height;
        boundWidth = (this.canvas.width * width) / carrierWidth;
        boundHeight = (((this.canvas.width * width) / carrierWidth) * height) / width;
      } else {
        x = boundX; //在createClipPath会再次计算
        y = boundY; //在createClipPath会再次计算
        clipRadius = (this.canvas.width * clipRadius) / carrierWidth;
        boundWidth = (this.canvas.width * boundWidth) / carrierWidth;
        boundHeight = (this.canvas.width * boundHeight) / carrierWidth;
      }

      // rx, ry 兼容圆角剪切

      this.clipPath = this.createClipPath({
        x,
        y,
        rx: clipRadius || 0,
        ry: clipRadius || 0,
        width: boundWidth,
        height: boundHeight,
        stroke: "#fff",
        defaultId: "clipPath",
        isCarrierClass: true,
      });
      this.clipPath.originX = "center";
      this.clipPath.originY = "center";
    },

    //初始化胸标 2023年2月1日17:10:06
    async initBadgeArea() {
      const { badgeX, badgeY, boundWidth, carrierWidth } = this.carrierConfig[this.perspective];
      if (badgeX == "0" && badgeY == "0") return;

      await new Promise(resolve =>
        //loadSVGFromURL 通过svg的url链接 xxx.svg 来载入对象iconGroup
        fabric.loadSVGFromURL(spec2, (objects, d) => {
          const iconGroup = fabric.util.groupSVGElements(objects, d);
          iconGroup.set({
            left: this.centerX + (badgeX / carrierWidth) * this.canvas.width,
            top: this.centerY + (badgeY / carrierWidth) * this.canvas.width,

            absolutePositioned: true,
            selectable: false,
            opacity: 0.5,
            defaultId: "spec2",
            isCarrierClass: true,
          });

          iconGroup.scale(((this.bound.width / boundWidth) * 10) / iconGroup.width);

          this.spec2 = iconGroup;
          this.canvas.add(this.spec2); // badge
          resolve();
        }),
      );
    },
    async initMCConfig() {
      await this.initLogo();
    },

    async initLogo() {
      const { logo_color: logoColor, logo_img: logoUrl } = this.mcConfig;
      const { logo_width: logoWidth, logo_x: logoX, logo_y: logoY, logo_angle: logoAngle, region_width: boundOriginWidth } = this.mcConfig.design_region[0];
      const { carrierWidth } = this.carrierConfig[this.perspective];
      await new Promise(resolve => {
        fabric.Image.fromURL(
          logoUrl,
          img => {
            img.set({
              left: this.centerX + (logoX / carrierWidth) * this.canvas.width,
              top: this.centerY + (logoY / carrierWidth) * this.canvas.width,
              originX: "center",
              originY: "center",
              selectable: false,
              angle: logoAngle,
              // defaultId: "mcConfig",
              // isCarrierClass: true
            });
            img.scale(((this.bound.width / boundOriginWidth) * logoWidth) / img.width);
            this.canvas.add(img);
            const color = this.getColorLibrary.filter(({ color: colorValue }) => colorValue === logoColor)[0];
            const tertiaryColor = { ...color };
            tertiaryColor.isLocked = true;
            tertiaryColor.isLogo = true;

            this.SET_COLOR_CONFIG({ ...this.getColorConfig, tertiaryColor });
            resolve();
          },
          { crossOrigin: "anonymous" },
        );
      });
    },

    // 【ID1000215】4.4 创作中心-用户-挑选载体
    async setCarrier() {
      Toast.loading({
        message: "",
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      this.resetZoom(); // 重置zoom level至 1
      this.canvas.discardActiveObject(); //取消所有选中贴纸
      this.canvas.offHistory(); // 关闭历史记录
      this.getObjectsByDefaultId("leftSleeveImage").forEach(o => {
        this.canvas.remove(o);
      }); //移除左右袖图片
      this.getObjectsByDefaultId("rightSleeveImage").forEach(o => {
        this.canvas.remove(o);
      });

      this.getObjectByDefaultId("bound").clone(cloned => {
        cloned.defaultId = "tempBound";
        cloned.opacity = 0;
        cloned.isCarrierClass = false;
        this.tempBound = cloned;
        this.canvas.add(cloned);
      });

      //设置默认颜色
      const carrier = this.canvas.getObjects().filter(o => o.defaultId === "carrierImage")[0]; //this.getObjectDefaultId()
      const colorConfig = { ...this.getColorConfig };
      colorConfig.defaultColor = {
        ...colorConfig.defaultColor,
        ...this.getColorLibrary.filter(item => item.id.toString() === this.carrierConfig.color_id)[0],
      } || {
        color: "",
        id: "",
        type: "",
        title: "",
        isLocked: true,
      };
      this.SET_COLOR_CONFIG(colorConfig);

      this.carrier = this.carrierConfig[this.perspective].carrierImage; //切换载体图片
      await new Promise(resolve => {
        carrier &&
          carrier.setSrc(
            this.carrierConfig[this.perspective].carrierImage,
            () => {
              this.canvas.renderAll();
              resolve();
            },
            { crossOrigin: "anonymous" },
          );
      });

      let { boundX, boundY, badgeX, badgeY, boundWidth, boundHeight, carrierWidth, clipRadius } = this.carrierConfig[this.perspective];
      let x, y, width, height;

      if (this.mcId && this.perspective === "front") {
        // 企业定制下的设置载体
        const { region_x, region_y, region_width, region_height } = this.mcConfig.design_region[0];
        x = region_x;
        y = region_y;
        width = region_width;
        height = region_height;
        boundWidth = (this.canvas.width * width) / carrierWidth;
        boundHeight = (((this.canvas.width * width) / carrierWidth) * height) / width;

        this.getObjectByDefaultId("bound").set({
          // 获取上次的设计区域并重新应用
          width: boundWidth,
          height: boundHeight,
          left: this.centerX + (x / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          top: this.centerY + (y / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
        });
        this.clipPath.set({
          width: boundWidth,
          height: boundHeight,
          left: this.centerX + (x / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          top: this.centerY + (y / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
        });
      } else {
        this.getObjectByDefaultId("bound").set({
          rx: (clipRadius / carrierWidth) * this.canvas.width ?? 5,
          ry: (clipRadius / carrierWidth) * this.canvas.width ?? 5,
          width: (boundWidth / carrierWidth) * this.canvas.width,
          height: (boundHeight / carrierWidth) * this.canvas.width,
          left: this.centerX + (boundX / carrierWidth) * this.canvas.width,
          top: this.centerY + (boundY / carrierWidth) * this.canvas.width,
        });

        this.boundCenterX = this.getObjectByDefaultId("bound").left;
        this.boundCenterY = this.getObjectByDefaultId("bound").top;

        this.clipPath.set({
          rx: (clipRadius / carrierWidth) * this.canvas.width,
          ry: (clipRadius / carrierWidth) * this.canvas.width,
          width: (boundWidth / carrierWidth) * this.canvas.width,
          height: (boundHeight / carrierWidth) * this.canvas.width,
          left: this.centerX + (boundX / carrierWidth) * this.canvas.width,
          top: this.centerY + (boundY / carrierWidth) * this.canvas.width,
        });

        const badge = this.getObjectByDefaultId("spec2"); // 获取上次的胸标并重新应用

        if (badge) {
          badge.set({
            left: this.centerX + (this.carrierConfig[this.perspective].badgeX / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
            top: this.centerY + (this.carrierConfig[this.perspective].badgeY / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          });
        }

        if (badgeX == "0" && badgeY == "0") {
          badge && (badge.opacity = 0);
        } else {
          badge && (badge.opacity = 0.5);
        }
      }
      if (!(this.carrierConfig.left == null && this.carrierConfig.right == null)) {
        if (this.perspective === "front" || this.perspective === "back") {
          this.getObjectsByDefaultId("leftSleeveImage").forEach(o => {
            this.canvas.remove(o);
          });
          this.getObjectsByDefaultId("rightSleeveImage").forEach(o => {
            this.canvas.remove(o);
          });

          this.initSleevesClipPath();
          this.$bus.$emit("left:exportPerspectiveCanvas");
          this.$bus.$emit("right:exportPerspectiveCanvas");
          this.$bus.$emit("front:renderPerspectiveCanvas");
          this.$bus.$emit("back:renderPerspectiveCanvas");
        }
      }

      const group = this.canvas.getObjects().filter(o => !o.isCarrierClass);

      const selectedObject = new fabric.ActiveSelection(group, {
        canvas: this.canvas,
      });
      this.canvas.setActiveObject(selectedObject); //定位已经做好的设计
      // const { left: al, top: at, width: aw, height: ah } = this.canvas.getActiveObject();
      // const ar = al + aw;
      // const ab = at + ah;
      // const { left: bl, top: bt, width: bw, height: bh } = this.getObjectByDefaultId("bound");
      // const br = bl + bw;
      // const bb = bt + bh;

      // if (ar < bl || ab < bt || al > br || at > bb) {

      this.canvas.centerObject(selectedObject);

      selectedObject.set({
        left: selectedObject.left + (this.carrierConfig[this.perspective].boundX / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.getZoom() * this.canvas.width,
        top: selectedObject.top + (this.carrierConfig[this.perspective].boundY / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.getZoom() * this.canvas.width,
      });
      // }

      this.canvas
        .getObjects()
        .filter(o => o.defaultId === "tempBound")
        .forEach(o => {
          this.canvas.remove(o);
        });
      this.canvas.discardActiveObject();
      this.canvas.renderAll();

      this.getAvatarObjects().forEach(o => {
        if (o.styleType == 2) {
          o.getObjects().forEach(icon => {
            if (icon.id === "light") {
              icon.set({ fill: this.getColorConfig.defaultColor.color });
            }
          });
        }
      });

      if (this.carrierConfig[this.perspective].overlayImage) {
        //如果有浮层
        this.initOverlay();
      } else {
        this.canvas.overlayImage = null;
      }

      this.canvas.renderAll();

      this.zoom150x();

      this.canvas.historyNextState = "";
      this.canvas.clearHistory();
      this.canvas.goOnHistory();
      Toast.clear();
    },

    getAvatarObjects() {
      return this.canvas.getObjects().filter(o => o.isAvatar);
    },

    initBus() {
      this.$bus.$on(this.perspective + ":addSvg", this.addSvg);
      this.$bus.$on(this.perspective + ":addFont", this.addFont);
      this.$bus.$on(this.perspective + ":setZoomToggle", this.setZoomToggle);
      this.$bus.$on(this.perspective + ":resetZoom", this.resetZoom);
      this.$bus.$on(this.perspective + ":undo", this.undo);
      this.$bus.$on(this.perspective + ":redo", this.redo);
      this.$bus.$on(this.perspective + ":bringForward", this.bringForward);
      this.$bus.$on(this.perspective + ":sendBackwards", this.sendBackwards);
      this.$bus.$on(this.perspective + ":setObjectColor", this.setObjectColor);
      this.$bus.$on(this.perspective + ":renderColorConfig", this.renderColorConfig);
      this.$bus.$on(this.perspective + ":exportCanvas", this.exportCanvas);
      this.$bus.$on(this.perspective + ":getStickerLength", this.getStickerLength);
      this.$bus.$on(this.perspective + ":unlockColorConfig", this.unlockColorConfig);
      this.$bus.$on(this.perspective + ":inputText", this.inputText);
      this.$bus.$on(this.perspective + ":alignText", this.alignText);
      this.$bus.$on(this.perspective + ":scaleText", this.scaleText);
      this.$bus.$on(this.perspective + ":addUploadedImage", this.addUploadedImage);
      this.$bus.$on(this.perspective + ":setCarrier", this.setCarrier);
      this.$bus.$on(this.perspective + ":enterDrawingMode", this.enterDrawingMode);
      this.$bus.$on(this.perspective + ":exitDrawingMode", this.exitDrawingMode);
      this.$bus.$on(this.perspective + ":setBrushSize", this.setBrushSize);
      this.$bus.$on(this.perspective + ":saveSnapshot", this.saveSnapshot);
      this.$bus.$on("loadFromTemplate", this.loadFromTemplate);
      this.$bus.$on(this.perspective + ":loadFromTemplate", this.loadFromTemplate);

      this.$bus.$on(this.perspective + ":zoomIn", this.zoomIn);
      this.$bus.$on(this.perspective + ":zoom150x", this.zoom150x);
      this.$bus.$on(this.perspective + ":replaceUploadedImage", this.replaceUploadedImage);

      if (this.perspective == "front" || this.perspective == "back") {
        this.$bus.$on(this.perspective + ":renderPerspectiveCanvas", this.renderPerspectiveCanvas);
      }
      if (this.perspective == "left" || this.perspective == "right") {
        this.$bus.$on(this.perspective + ":exportPerspectiveCanvas", this.exportPerspectiveCanvas);
      }
    },
    destroyBus() {
      this.$bus.$off(this.perspective + ":addSvg");
      this.$bus.$off(this.perspective + ":addFont");
      this.$bus.$off(this.perspective + ":setZoomToggle");
      this.$bus.$off(this.perspective + ":resetZoom");
      this.$bus.$off(this.perspective + ":undo");
      this.$bus.$off(this.perspective + ":redo");
      this.$bus.$off(this.perspective + ":bringForward");
      this.$bus.$off(this.perspective + ":sendBackwards");
      this.$bus.$off(this.perspective + ":setObjectColor");
      this.$bus.$off(this.perspective + ":renderColorConfig");
      this.$bus.$off(this.perspective + ":exportCanvas");
      this.$bus.$off(this.perspective + ":getStickerLength");
      this.$bus.$off(this.perspective + ":unlockColorConfig");
      this.$bus.$off(this.perspective + ":inputText");
      this.$bus.$off(this.perspective + ":alignText");
      this.$bus.$off(this.perspective + ":scaleText");
      this.$bus.$off(this.perspective + ":addUploadedImage");
      this.$bus.$off(this.perspective + ":setCarrier");
      this.$bus.$off(this.perspective + ":enterDrawingMode");
      this.$bus.$off(this.perspective + ":exitDrawingMode");
      this.$bus.$off(this.perspective + ":setBrushSize");
      this.$bus.$off(this.perspective + ":saveSnapshot");
      this.$bus.$off("loadFromTemplate");
      this.$bus.$off(this.perspective + "loadFromTemplate");

      this.$bus.$off(this.perspective + ":zoomIn");
      this.$bus.$off(this.perspective + ":zoom150x");
      this.$bus.$on(this.perspective + ":replaceUploadedImage");

      if (this.perspective == "front" || this.perspective == "back") {
        this.$bus.$off(this.perspective + ":renderPerspectiveCanvas");
      }
      if (this.perspective == "left" || this.perspective == "right") {
        this.$bus.$off(this.perspective + ":exportPerspectiveCanvas");
      }
    },
    async renderPerspectiveCanvas() {
      const { leftX, leftY, leftWidth, leftHeight, leftAngle, rightX, rightY, rightWidth, rightHeight, rightAngle, carrierWidth } = this.carrierConfig[this.perspective];

      const leftSleeve = this.canvas.getObjects().filter(o => o.defaultId === "leftSleeveImage")[0];
      const rightSleeve = this.canvas.getObjects().filter(o => o.defaultId === "rightSleeveImage")[0];

      if (!leftSleeve) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            this.getSleeveImages["leftSleeveImage"],
            async img => {
              img.set({
                left: (leftX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.2,
                top: (leftY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.2,
                originX: "center",
                originY: "center",
                backgroundColor: "transparent",
                selectable: false,
                defaultId: "leftSleeveImage",
                isCarrierClass: true,
                angle: leftAngle,
              });

              img.clipPath = this.leftSleeveClipPath;
              img.scale(((leftWidth / carrierWidth) * this.canvas.width) / img.width);

              this.leftSleeve = img;
              this.canvas.add(img);

              this.canvas.renderAll();
              resolve();
            },
            { crossOrigin: "Anonymous" },
          );
        });
      } else {
        await new Promise(resolve => {
          leftSleeve.setSrc(this.getSleeveImages["leftSleeveImage"], () => {
            leftSleeve.scale(((10 / carrierWidth) * this.canvas.width) / leftSleeve.width);
            leftSleeve.setCoords();
            this.canvas.renderAll();
            resolve();
          });
        });
      }

      if (!rightSleeve) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            this.getSleeveImages["rightSleeveImage"],
            async img => {
              img.set({
                left: (rightX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.2,
                top: (rightY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.2,
                originX: "center",
                originY: "center",
                backgroundColor: "transparent",
                selectable: false,
                defaultId: "rightSleeveImage",
                isCarrierClass: true,
                angle: rightAngle,
              });
              img.clipPath = this.rightSleeveClipPath;
              img.scale(((rightWidth / carrierWidth) * this.canvas.width) / img.width);
              this.rightSleeve = img;
              this.canvas.add(img);
              this.canvas.renderAll();
              resolve();
            },
            { crossOrigin: "Anonymous" },
          );
        });
      } else {
        await new Promise(resolve => {
          rightSleeve.setSrc(this.getSleeveImages["rightSleeveImage"], () => {
            rightSleeve.scale(((10 / carrierWidth) * this.canvas.width) / rightSleeve.width);
            rightSleeve.setCoords();
            this.canvas.renderAll();
            resolve();
          });
        });
      }
    },
    async exportPerspectiveCanvas() {
      this.clipPath && (this.clipPath.opacity = 0);
      this.bound && (this.bound.opacity = 0);
      this.spec2 && (this.spec2.opacity = 0);
      this.carrierImage && (this.carrierImage.opacity = 0);
      const outputCanvasImage = this.canvas.toDataURL({
        width: this.clipPath.width * this.canvas.getZoom(),
        height: this.clipPath.height * this.canvas.getZoom(),
        left: this.clipPath.left - this.clipPath.width / 2,
        top: this.clipPath.top - this.clipPath.height / 2,
        multiplier: 300 / this.clipPath.width,
        format: "png",
        crossOrigin: "Anonymous",
      });
      this.bound && (this.bound.opacity = 0.5);
      this.clipPath && (this.clipPath.opacity = 0.5);
      this.carrierImage && (this.carrierImage.opacity = 1);

      this[`SET_${this.perspective.toUpperCase()}_SLEEVE_IMAGE`](outputCanvasImage);
    },

    unlockColorConfig() {
      const colorConfig = { ...this.getColorConfig };
      for (let key in this.getColorConfig) {
        if (key == "defaultColor" || this.getColorConfig[key].isLogo == true) {
          continue;
        }
        colorConfig[key].isLocked = false;
      }
      this.SET_COLOR_CONFIG(colorConfig);
    },

    setObjectColor(value) {
      console.log(value);
      this.setBrushColor(value);
      if (this.carrierConfig.is_knitting == "1") {
        const colorKey = value?.colorKey;
        if (!colorKey) {
          if (this.canvas.getActiveObject()) {
            if (this.canvas.getActiveObject()["_objects"]) {
              this.canvas.getActiveObject()["_objects"].forEach(obj => {
                obj.colorKey && obj.set("currentColor", this.getColorConfig[obj.colorKey].color);
              });
            } else {
              this.canvas.getActiveObject()?.colorKey && this.canvas.getActiveObject().set("currentColor", this.getColorConfig[this.canvas.getActiveObject().colorKey].color);
            }
          }
          this.renderColorConfig();
          return;
        }
        if (this.canvas.getActiveObject()) {
          if (this.canvas.getActiveObject()["_objects"]) {
            this.canvas.getActiveObject()["_objects"].forEach(obj => {
              obj.set("colorKey", colorKey);
              obj.set("currentColor", this.getColorConfig[colorKey].color);
            });
          } else {
            this.canvas.getActiveObject().set("colorKey", colorKey);
            this.canvas.getActiveObject().set("currentColor", this.getColorConfig[colorKey].color);
          }
          this.renderColorConfig();
        }
      } else {
        console.log(this.canvas.getActiveObject());
        if (this.canvas.getActiveObject()) {
          if (this.canvas.getActiveObject().type == "activeSelection") {
            this.canvas.getActiveObject()["_objects"].forEach(obj => {
              obj.set("currentColor", value);
              console.log(obj);
              if (obj.defaultId === "drawingPath") {
                obj["_objects"].forEach(o => {
                  o.set("stroke", value);
                });
              } else {
                obj.set("fill", value);
              }
            });
          } else {
            this.canvas.getActiveObject().set("currentColor", value);
            if (this.canvas.getActiveObject().defaultId === "drawingPath") {
              console.log(this.canvas.getActiveObject());
              this.canvas.getActiveObject()["_objects"].forEach(obj => {
                obj.set("currentColor", value);
                obj.set("stroke", value);
              });
            } else {
              this.canvas.getActiveObject().set("fill", value);
            }
          }
        }
      }
      this.carrierImage.colorConfig = this.getColorConfig;
      this.canvas.renderAll();
    },

    renderColorConfig() {
      // if (this.mode === "ipmode") return;
      if (!this.carrierConfig.is_knitting == "1") return;
      this.canvas.getObjects().forEach(obj => {
        if (![1].includes(obj.typeId) && this.$route.matched[1].path.indexOf("artist") == -1) {
          if (obj.typeId != 1) {
            if (obj.defaultId === "drawingPath") {
              obj.colorKey && obj.set("stroke", this.getColorConfig[obj.colorKey].color);
            } else {
              obj.colorKey && obj.set("fill", this.getColorConfig[obj.colorKey].color);
            }
          }
        }
      });
      this.canvas.renderAll();
    },

    // 【ID1000271】4.15.2 创作中心-用户-撤回
    undo: _.debounce(async function () {
      console.log(this.canvas);
      await new Promise(resolve => {
        this.canvas.undo(() => {
          resolve();
        });
      });
      this.canvas.offHistory();

      this.canvas.getObjects().forEach(o => {
        if (o.typeId == 1) {
          //设置贴纸控制器的配置
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }
        if (o.defaultId == "carrierImage") {
          //设置载体的参数
          this.carrierImage = o;
          this.carrierConfig.is_knitting == "1" && this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...o.colorConfig });
        }

        if (o.defaultId == "") {
          //设置画笔的参数
          if (this.canvas.isDrawingMode == true) {
            o.defaultId = "drawingPath";
            o.batchId = this.batchId;
            o.clipPath = this.clipPath;
          }
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }

        if (o.defaultId == "drawingPath") {
          //设置画笔控制器的参数
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }
      });

      (this.perspective === "front" || this.perspective === "back") && (await this.renderPerspectiveCanvas());
      await this.resetHistoryCanvas();
      this.canvas.goOnHistory();
    }, 200),

    // 【ID1000271】4.15.2 创作中心-用户-重做

    redo: _.debounce(async function () {
      await new Promise(resolve => {
        this.canvas.redo(() => {
          resolve();
        });
      });
      this.canvas.offHistory();

      this.canvas.getObjects().forEach(o => {
        if (o.typeId == 1 || o.defaultId == "drawingPath") {
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }
        if (o.defaultId == "carrierImage") {
          this.carrierImage = o;
          this.carrierConfig.is_knitting == "1" && this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...o.colorConfig });
        }
      });

      (this.perspective === "front" || this.perspective === "back") && (await this.renderPerspectiveCanvas());
      await this.resetHistoryCanvas();
      this.canvas.goOnHistory();
    }, 200),

    resetColorConfig() {},

    async resetHistoryCanvas() {
      // this.canvas.getObjects().forEach(o => {
      //   if (o.isCarrierClass) {
      //     this.canvas.remove(o);
      //   }
      // });
      // await this.initCarrier();
      // await this.initBound();
      // (!this.mcId && this.perspective === 'front') && (await this.initBadgeArea());
      this.carrierImage = this.getObjectByDefaultId("carrierImage");
      if (this.getObjectByDefaultId("spec2")) this.spec2 = this.getObjectByDefaultId("spec2");
      this.bound = this.getObjectByDefaultId("bound");

      this.resetInitialObjects();
    },

    // 【ID1000272】4.15.3 创作中心-用户-图层顺序 上移图层
    bringForward() {
      this.canvas.bringForward(this.canvas.getActiveObject());
      this.resetInitialObjects();
      this.canvas.renderAll();
    },

    // 【ID1000272】4.15.3 创作中心-用户-图层顺序 下移图层
    sendBackwards() {
      this.canvas.sendBackwards(this.canvas.getActiveObject());
      this.resetInitialObjects();
      this.canvas.renderAll();
    },

    resetInitialObjects() {
      const spec2 = this.getObjectByDefaultId("spec2");
      const bound = this.getObjectByDefaultId("bound");
      const carrierImage = this.getObjectByDefaultId("carrierImage");
      if (spec2) this.canvas.sendToBack(spec2);
      if (bound) this.canvas.sendToBack(bound);
      if (carrierImage) this.canvas.sendToBack(carrierImage);
    },

    canvasIsEmpty() {
      return this.canvas.getObjects().filter(o => !o.isCarrierClass).length === 0;
    },

    getObjectByDefaultId(id) {
      return this.canvas.getObjects().filter(o => o.defaultId === id)[0];
    },

    getObjectsByDefaultId(id) {
      return this.canvas.getObjects().filter(o => o.defaultId === id);
    },

    //【ID1000270】4.15.1 创作中心-用户-工具栏放大缩小

    setZoomToggle() {
      const widthZoom = this.canvas.width / (this.bound.width + 10);
      const canvasRatio = this.canvas.height / this.canvas.width;
      const boundRatio = this.bound.height / this.bound.width;

      let zoomValue;

      if (boundRatio > canvasRatio) {
        if (canvasRatio < 1) {
          zoomValue = widthZoom;
        } else {
          zoomValue = 2;
        }
      } else {
        zoomValue = widthZoom;
      }

      if (this.perspective === "left" || this.perspective === "right") {
        zoomValue = 2;
      }

      if (this.zoomToggle) {
        this.resetZoom();
        this.SET_ZOOM_TOGGLE(true);
        this.canvas.zoomToPoint(
          // 以框中心点为中心放大
          new fabric.Point(this.bound.left, this.bound.top),
          zoomValue.toFixed(2),
        );

        // 计算框中心点和画布中心点的偏移量，并且平移
        this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top));
      } else {
        this.resetZoom();
        this.zoom150x();
      }

      this.canvas.renderAll();
    },
    resetZoom() {
      this.SET_ZOOM_TOGGLE(false);
      this.canvas.setZoom(1);
      this.canvas.absolutePan(new fabric.Point(0, 0));
      this.canvas.renderAll();
    },
    zoomIn() {
      this.resetZoom();
      this.SET_ZOOM_TOGGLE(true);

      const widthZoom = this.canvas.width / (this.bound.width + 10);
      const canvasRatio = this.canvas.height / this.canvas.width;
      const boundRatio = this.bound.height / this.bound.width;

      let zoomValue;

      if (boundRatio > canvasRatio) {
        if (canvasRatio < 1) {
          zoomValue = widthZoom;
        } else {
          zoomValue = 2;
        }
      } else {
        zoomValue = widthZoom;
      }

      if (this.perspective === "left" || this.perspective === "right") {
        zoomValue = 2;
      }

      this.canvas.zoomToPoint(new fabric.Point(this.bound.left, this.bound.top), zoomValue.toFixed(2));
      this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top));

      this.canvas.renderAll();
    },
    //【ID1000307】创作中心正常状态载体放大
    zoom150x() {
      this.SET_ZOOM_TOGGLE(false);
      this.resetZoom();

      const widthZoom = this.canvas.width / (this.bound.width + 10);
      let zoomValue;

      if (widthZoom < 1.5) {
        zoomValue = widthZoom;
      } else {
        zoomValue = 1.5;
      }

      this.canvas.zoomToPoint(new fabric.Point(this.bound.left, this.bound.top), zoomValue);
      this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top));
      this.canvas.renderAll();
    },

    //【ID1000226】4.8 创作中心-用户-画笔

    setBrushSize(size) {
      //设置笔刷大小
      this.canvas.freeDrawingBrush.width = parseInt(size);
    },
    setBrushColor(color) {
      //设置笔刷颜色
      console.log({ color });
      this.canvas.freeDrawingBrush.color = color;
    },
    enterDrawingMode() {
      //进入画笔模式
      this.canvas.discardActiveObject(); //取消选择 活动对象
      this.canvas.isDrawingMode = true; // true进入画画模式, false退出画笔模式
      this.batchId = new Date().getTime(); // 记录起始作画时间戳，并成为ID，目的让这一批画出来的产物进行编组
      this.SET_IS_DRAWING_MODE(true);
      this.$bus.$emit("showMenuPopup", {
        colorKey: this.activeColorKey,
        tab: "color",
      });

      this.setBrushColor(this.activeColor);
      this.canvas.on("path:created", e => {
        console.log("画笔功能数据", this.canvas.toDatalessJSON().objects);

        // 画完一笔的事件
        console.log("path:created");
        const { path } = e;
        path.clipPath = this.clipPath; // 要做剪切,在设计区域内
        path.colorKey = this.activeColorKey; // primaryColor
        path.currentColor = this.activeColor; // 改当前颜色
        path.defaultId = "drawingPath";
        path.batchId = this.batchId;
        path.setControlsVisibility({
          // 设置控制器
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          tr: false,
          editControl: false,
        });
      });
      this.canvas.renderAll();
    },

    exitDrawingMode() {
      // 退出画笔模式
      this.SET_IS_DRAWING_MODE(false);
      this.canvas.offHistory();
      this.canvas.historyNextState = "";
      this.canvas.historyUndo = [];
      this.canvas.historyRedo = [];
      this.canvas.clearHistory();
      this.canvas.goOnHistory();
      // 将某批次画出来的产物编组
      const paths = this.canvas.getObjects().filter(o => o.batchId === this.batchId);
      paths.forEach(path => {
        path.clipPath = null;
      });

      const selectedObject = new fabric.ActiveSelection(paths, {
        canvas: this.canvas,
      }); //指定画布上选中

      this.canvas.renderAll();
      if (paths.length > 0) {
        const group = new fabric.Group(paths, {
          defaultId: "drawingPath",
          clipPath: this.clipPath,
          left: selectedObject.left,
          top: selectedObject.top,
        });
        paths.forEach(path => {
          this.canvas.remove(path);
        });

        // 设置编组的控制按钮

        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          tr: false,
          editControl: false,
        });
        this.canvas.add(group);
        this.canvas.renderAll();
      }

      // this.canvas.discardActiveObject();
      this.canvas.isDrawingMode = false;
      this.canvas.renderAll();
    },

    async addSvg({ url, options }) {
      /* 
        typeId: 
          1: 头像
          2: 字体
          4: 形状
          5: 贴纸
          6: 素材
          10: 照片上传
      */

      const typeId = options?.type || 4;
      const ipId = options?.ipId || "";
      const elementId = options?.elementId || "";
      const fromAvatar = options?.fromAvatar || false;
      const styleType = options?.styleType || 0;
      const isImage = /.(jpg|png|jpeg)/.test(url);

      console.log(isImage);

      Toast.loading({
        message: "",
        forbidClick: true,
        duration: 0,
      });

      const hasSticker = this.canvas.getObjects().filter(o => o.typeId == 1).length == 1 && this.canvas.getObjects().filter(o => !o.isCarrierClass && (o.typeId != 2 || o.typeId != 4)).length > 0;
      const hasNonSticker = this.canvas.getObjects().filter(o => o.typeId == 1).length == 0 && this.canvas.getObjects().filter(o => !o.isCarrierClass && (o.typeId != 2 || o.typeId != 4)).length > 0;
      const isArtist = this.$route.matched[1].path.indexOf("artist") > -1;

      if (isArtist) {
        let canvasIsEmpty = true;
        for (let key in this.objectIsEmpty) {
          if (this.objectIsEmpty[key] == false) {
            canvasIsEmpty = false;
            break;
          }
        }
        // let canvasIsEmpty = true;
        // Object.keys(this.objectIsEmpty).forEach(key => {
        //   if (this.objectIsEmpty[key]) {
        //     return;
        //   }
        //   if (this.objectIsEmpty[key] == false) {
        //     canvasIsEmpty = false;
        //   }
        // });
        if (canvasIsEmpty) this.SET_CURRENT_IP_ID("");

        if (!this.currentIpId) {
          this.SET_CURRENT_IP_ID(ipId);
          // this.SET_OBJECT_IS_EMPTY({ ...this.objectIsEmpty, [this.perspective]: false });
        }
        if (this.currentIpId != ipId) {
          Toast.clear();
          await Dialog.alert({
            message: "请选择同一个艺术家的贴纸",
            confirmButtonColor: "#23d59c",
          });
          return;
        }
        this.canvas.remove(
          this.canvas
            .getObjects()
            .filter(o => o.isArtist)
            .forEach(o => {
              this.canvas.remove(o);
            }),
        );
      }

      if (typeId == 1 && this.carrierConfig.is_knitting == "1") {
        if (hasSticker || hasNonSticker) {
          Toast.clear();
          const result = await Dialog.confirm({
            message: "所选贴纸将重置调色盘",
            confirmButtonColor: "#23d59c",
          });
          if (result !== "confirm") {
            return;
          }
        }

        this.canvas.offHistory();
        this.canvas.remove(
          this.canvas
            .getObjects()
            .filter(o => o.typeId == 1)
            .forEach(o => {
              this.canvas.remove(o);
            }),
        );
        this.canvas.goOnHistory();
      }

      const colorConfig = {
        ...this.getColorConfig,
      };

      if (typeId == 1 && this.carrierConfig.is_knitting == "1") {
        let primaryValue = options.value1 ? options.value1.toUpperCase() : "",
          secondaryValue = options.value2 ? options.value2.toUpperCase() : "",
          tertiaryValue = options.value3 ? options.value3.toUpperCase() : "";

        this.setColorConfig({
          primaryValue,
          secondaryValue,
          tertiaryValue,
        });
      }

      //如果传进来是图片
      //begin

      if (isImage) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            url + "?x-oss-process=image/format,png",
            iconGroup => {
              iconGroup.set({
                left: this.boundCenterX || this.centerX,
                top: this.boundCenterY || this.centerY,
                originX: "center",
                originY: "center",
                // selectable: true,
                lockScalingFlip: true,
                colorKey: typeId == 1 ? "" : this.activeColorKey,
                typeId: typeId,
                isArtist: isArtist,
                ipId: ipId,
                elementId: elementId,
                styleType,
                defaultId: "image",
              });

              this.carrierConfig.is_knitting == "1" && this.SET_ACTIVE_COLOR_KEY(typeId == 1 ? "primaryColor" : this.activeColorKey);

              iconGroup.clipPath = this.clipPath;

              const basic = iconGroup.width > iconGroup.height ? iconGroup.width : iconGroup.height;

              // if (typeId == 1 || isArtist) {
              //   iconGroup.minScaleLimit = ((this.clipPath.width / 35) * 1) / basic;
              // } else {
              //   iconGroup.minScaleLimit = this.clipPath.width / 35 / basic;
              // }

              iconGroup.scale(60 / basic);

              this.carrierConfig.is_knitting == "1" && this.renderColorConfig();
              this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig });

              iconGroup.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                tr: false,
                editControl: false,
              });

              if (isArtist) {
                iconGroup.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  bl: false,
                  br: false,
                  tl: false,
                  tr: false,
                  mtr: false,
                  duplicateControl: false,
                  rotateControl: false,
                  editControl: false,
                });
                let scale; //【ID1000340】【Bug转需求】IP创作中心图片展示比之前小 IP图3/4画布 贴子1/4画布
                if (iconGroup.width > iconGroup.height) {
                  scale = (this.bound.width / iconGroup.width) * 0.75;
                } else {
                  scale = (this.bound.height / iconGroup.height) * 0.75;
                }
                iconGroup.scale(scale);
              }

              if (typeId == 6) {
                let scale;
                if (iconGroup.width > iconGroup.height) {
                  scale = (this.bound.width / iconGroup.width) * 0.75;
                } else {
                  scale = (this.bound.height / iconGroup.height) * 0.75;
                }
                iconGroup.scale(scale);
                // iconGroup.set({ scaleX: scale });
              }

              iconGroup.on("scaling", () => {
                this.canvas.oMoving = true; //元素正在移动
              });

              this.canvas.add(iconGroup);
              this.canvas.setActiveObject(iconGroup);

              this.canvas.renderAll();
              resolve();
            },
            { crossOrigin: "Anonymous" },
          );
        });
        Toast.clear();
        return;
      }

      //end

      const loadSvg = fromAvatar ? "loadSVGFromString" : "loadSVGFromURL";

      fabric[loadSvg](url, (objects, d) => {
        const iconGroup = fabric.util.groupSVGElements(objects, d);
        iconGroup.set({
          left: this.boundCenterX || this.centerX,
          top: this.boundCenterY || this.centerY,
          originX: "center",
          originY: "center",
          // selectable: true,
          lockScalingFlip: true,
          colorKey: typeId == 1 ? "" : this.activeColorKey,
          typeId: typeId,
          isArtist: isArtist,
          ipId: ipId,
          elementId: elementId,
          styleType,
          defaultId: "svg",
        });

        if (this.svgOffset == 0) {
          this.svgOffset = -5;
        } else {
          this.svgOffset = 0;
        }
        iconGroup.set({ left: iconGroup.left + this.svgOffset, top: iconGroup.top + this.svgOffset });

        this.carrierConfig.is_knitting == "1" && this.SET_ACTIVE_COLOR_KEY(typeId == 1 ? "primaryColor" : this.activeColorKey);

        if (!isArtist) {
          iconGroup.set({
            fill: colorConfig[this.activeColorKey]?.color || this.activeColor,
          });
        }

        if (fromAvatar) {
          iconGroup.set({
            isAvatar: fromAvatar,
          });
          if (styleType == 2) {
            iconGroup.getObjects().forEach(icon => {
              if (icon.id === "light") {
                icon.set({ fill: colorConfig.defaultColor.color });
              }
            });
          }
        }

        iconGroup.clipPath = this.clipPath;

        const basic = iconGroup.width > iconGroup.height ? iconGroup.width : iconGroup.height;

        // if (typeId == 1 || isArtist) {
        //   iconGroup.minScaleLimit = ((this.clipPath.width / 35) * 1) / basic;
        // } else {
        //   iconGroup.minScaleLimit = this.clipPath.width / 35 / basic;
        // }

        iconGroup.scale(iconGroup.minScaleLimit > 42 / basic ? iconGroup.minScaleLimit : 42 / basic);

        this.carrierConfig.is_knitting == "1" && this.renderColorConfig();
        this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig });

        if (typeId == 1) {
          iconGroup.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }

        console.log(isArtist);

        if (isArtist) {
          iconGroup.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false,
            duplicateControl: false,
            rotateControl: false,
            editControl: false,
          });
          let scale;
          if (iconGroup.width > iconGroup.height) {
            scale = (this.bound.width / iconGroup.width) * 0.75;
          } else {
            scale = (this.bound.height / iconGroup.height) * 0.75;
          }
          iconGroup.scale(scale);
        }

        if (typeId == 6) {
          let scale;
          if (iconGroup.width > iconGroup.height) {
            scale = (this.bound.width / iconGroup.width) * 0.75;
          } else {
            scale = (this.bound.height / iconGroup.height) * 0.75;
          }
          iconGroup.scale(scale);
        }

        iconGroup.on("moving", () => {
          this.canvas.oMoving = true;
        });

        iconGroup.on("scaling", () => {
          this.canvas.oMoving = true;
        });

        this.canvas.add(iconGroup);
        this.canvas.setActiveObject(iconGroup);
        // this.objectIsEmpty[this.perspective] = this.canvas.getObjects().filter(o => o.isArtist).length == 0;
        // this.SET_OBJECT_IS_EMPTY(this.objectIsEmpty);

        this.canvas.renderAll();
        Toast.clear();
      });
    },

    //【ID1000267】4.13 创作中心-用户-颜色选择

    setColorConfig({ primaryValue, secondaryValue, tertiaryValue }) {
      const primaryColor = {
        ...this.getColorLibrary.filter(c => c.color === primaryValue)[0],
      };
      const secondaryColor = {
        ...this.getColorLibrary.filter(c => c.color === secondaryValue)[0],
      };
      const tertiaryColor = {
        ...this.getColorLibrary.filter(c => {
          return c.color === tertiaryValue;
        })[0],
      };

      // 设置三种颜色

      const colorConfig = {
        primaryColor,
        secondaryColor,
        tertiaryColor,
      };

      // 给每个颜色属性fill赋值

      Object.keys(colorConfig).forEach(key => {
        if (this.getColorConfig.defaultColor.color === colorConfig[key].color) {
          return;
        }

        if (!Object.keys(colorConfig[key]).length) {
          colorConfig[key] = this.getColorConfig[key];
          colorConfig[key].isLocked = false;
          if (this.mcId && key === "tertiaryColor") {
            colorConfig[key].isLocked = this.getColorConfig.tertiaryColor.isLogo;
          }
          return;
        }
        colorConfig[key].isLocked = true;
        if (this.mcId && key === "tertiaryColor") {
          colorConfig[key].isLocked = this.getColorConfig.tertiaryColor.isLogo;
        }
      });

      this.SET_COLOR_CONFIG({
        ...this.getColorConfig,
        ...colorConfig,
      });
    },
    getStickerLength(cb) {
      return cb(this.canvas.getObjects().filter(o => o.typeId == 1).length);
    },
    resizeCanvas() {
      this.canvas.setDimensions({
        width: this.$refs.fabricContainer.offsetWidth,
        height: this.$refs.fabricContainer.offsetHeight,
      });
      this.centerX = this.canvas.width / 2;
      this.centerY = this.canvas.height / 2;
      this.canvas.renderAll();
    },

    async addImage(url, { selectable }) {
      await new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          async img => {
            img.set({
              left: this.centerX,
              top: this.centerY,
              originX: "center",
              originY: "center",
              scaleX: (this.canvas.width / img.width).toFixed(2),
              scaleY: (this.canvas.width / img.width).toFixed(2),
              backgroundColor: "transparent",
              selectable,
              defaultId: "carrierImage",
              isCarrierClass: true,
            });
            img.colorConfig = { ...this.getColorConfig };
            this.carrierImage = img;
            this.imgRatio = img.width / img.height;
            this.canvas.add(img);
            this.canvas.moveTo(img, 0);
            resolve();
          },
          { crossOrigin: "Anonymous" },
        ),
      );
    },
    async addFont({ url, options: { fontFamily, elementId } }) {
      // 【ID1000263】4.16 创作中心-用户-字体
      const font = new FontFace(fontFamily, `url(${url})`); // 字体文件 url https://mp-oss.tootools.cn/xxx/xxx.ttf格式
      const status = await font.load().then(() => document.fonts.add(font)); //预加载字体
      let activeText = this.canvas.getActiveObject();

      if (activeText?.defaultId === "text") {
        activeText.fontFamily = fontFamily;
        this.canvas.renderAll();
      } else {
        this.addTextBox({
          textPlaceholder: "enter",
          fontFamily,
          elementId,
          url,
        });
      }
    },
    /*
     * @description: 添加文字
     * @param {url, textPlaceholder, fontFamily, elementId} url: 字体url textPlaceholder: 初始化文字-enter fontFamily: 字体 elementId: 元素id
     */

    addTextBox({ url, textPlaceholder, fontFamily, elementId }) {
      const colorConfig = { ...this.getColorConfig }; // 不看这个
      const textBox = new fabric.Textbox(textPlaceholder, {
        //初始化文字 textPlaceholder
        left: this.boundCenterX || this.centerX, // 设计框中点
        top: this.boundCenterY || this.centerY,
        minScaleLimit: 0.3, //zoom最小值
        originX: "center",
        originY: "center",
        fill: colorConfig[this.activeColorKey].color,
        fontSize: 18, // 初始字体大小
        fontFamily,
        lineHeight: 1, //行高 100% 字体多大行高就多高
        url,
        // fontWeight: "bold",
        fontStyle: "normal",
        centeredScaling: true, // 缩放时是否保持中心点不变
        textAlign: "justify-center", // 文字对齐方式 文本框居中
        // charSpacing: 150,
        cursorWidth: 0, //光标宽度
        editable: false, //是否可编辑，只能通过弹窗的文本框修改

        //以下为自定义属性
        ipId: "0",
        elementId, //字体的ID 中台提供的
        colorKey: this.activeColorKey,
        typeId: "2", //字体的typeId 恒为2
        defaultId: "text",
      });

      const textContent = textBox.text;
      const sliderMinVal = textBox.minScaleLimit;
      const sliderVal = textBox.scaleX;
      const colorKey = textBox.colorKey;
      this.$bus.$emit("showMenuPopup", {
        //颜色菜单 MenuPopup.vue
        isText: true, //告诉弹窗是文字，直接定位到文本输入框
        textContent, //文字内容
        sliderMinVal, //最小缩放值 滑块 已作废
        sliderVal, //当前缩放值
        colorKey, //当前颜色key
        tab: "input", //默认选中文本输入框
      });

      textBox.on("editing:entered", () => {
        this.textContent = textBox.get("text"); //同步输入框和文字框的内容
      });
      textBox.on("editing:exited", () => {
        let width = textBox.dynamicMinWidth; //同步文字框宽度
        textBox.set({ width });
      });
      textBox.on("changed", () => {
        let width = textBox.dynamicMinWidth;
        textBox.set({ width });
        this.canvas.renderAll();
      });

      this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig });

      textBox.clipPath = this.clipPath; //和贴纸一样，要加在设计区域内
      this.canvas.add(textBox);
      this.canvas.setActiveObject(textBox);

      this.canvas.renderAll();
    },

    inputText(textContent) {
      const textBox = this.canvas.getActiveObject(); //获取到当前选中的文字框

      let width = textBox.dynamicMinWidth; //获取当前文字框的宽度
      textBox.set({
        text: textContent.replace(/[\u0020|\u3000]/g, "\u00A0").replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ""),
      }); //屏蔽emoji
      textBox.set({ width });
      this.$bus.$emit(this.currentPerspective + ":setTextContent", textContent); //同步输入框和文字框的内容

      this.canvas.renderAll();
    },

    setFontSize(e) {
      //设置字体大小
      const textBox = this.canvas.getActiveObject();
      let width = textBox.dynamicMinWidth;

      textBox.set({
        fontSize: e,
      });
      textBox.set({ width });
      this.canvas.renderAll();
    },

    setFontFamily(fontFamily) {
      // TODO:
      //设置字体
      const textBox = this.canvas.getActiveObject();
      textBox.set({
        fontFamily,
      });
      this.canvas.renderAll();
    },

    alignText(pos) {
      //已作废
      //设置字体对齐方式
      const textBox = this.canvas.getActiveObject();
      textBox.set({
        textAlign: pos,
      });
      this.canvas.renderAll();
    },

    scaleText(scale) {
      //已作废
      const textBox = this.canvas.getActiveObject();
      textBox.scale(scale);
      this.canvas.renderAll();
    },
    // 创建剪切蒙版以及白色矩形
    createClipPath({ x, y, width, height, stroke, rx, ry }) {
      rx = rx || 0;
      ry = ry || 0;
      const { carrierWidth } = this.carrierConfig[this.perspective];
      const rect = new fabric.Rect({
        left: this.centerX + (x / carrierWidth) * this.canvas.width,
        top: this.centerY + (y / carrierWidth) * this.canvas.width,
        width,
        height,
        rx,
        ry,
        absolutePositioned: true,
      });
      console.log("rx", rx, ry);

      this.bound = new fabric.Rect({
        left: this.centerX + (x / carrierWidth) * this.canvas.width,
        top: this.centerY + (y / carrierWidth) * this.canvas.width,
        rx,
        ry,
        width,
        height,
        absolutePositioned: true,
        stroke, //描边色
        selectable: false,
        fill: "transparent", // 填充色
        opacity: 0.5,
        defaultId: "bound",
        isCarrierClass: true,
      });

      this.boundCenterX = this.bound.left;
      this.boundCenterY = this.bound.top;

      this.canvas.add(this.bound);
      this.canvas.moveTo(this.bound, 10);
      return rect;
    },

    initEvents() {
      this.canvas.on("selection:created", e => {
        const group = e.selected;
        const isSingle = group.length === 1;

        if (isSingle) {
          if (group[0]["colorKey"]) {
            this.SET_ACTIVE_COLOR_KEY(group[0]["colorKey"]);
          }
          return;
        }

        const hasSticker = !!group.filter(o => o.typeId == 1).length;
        const hasText = !!group.filter(o => o.type == "textbox").length;
        const hasDrawingPath = !!group.filter(o => o.defaultId == "drawingPath").length;

        if (hasSticker || hasText || hasDrawingPath) {
          this.canvas.getActiveObject().setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          });
        }
      });

      this.canvas.on("selection:updated", e => {
        const group = e.selected;
        const isSingle = group.length === 1;
        if (isSingle) {
          const object = group[0];
          if (object.type === "textbox") {
            this.$bus.$emit("showMenuPopup", {
              isText: true,
              textContent: object.text,
              sliderMinVal: object.minScaleLimit,
              sliderVal: object.scaleX,
              colorKey: object.colorKey,
              tab: "input",
            });
          } else {
            this.$bus.$emit("hideMenuPopup");
          }
        }

        if (isSingle) {
          if (group[0]["colorKey"]) {
            this.SET_ACTIVE_COLOR_KEY(group[0]["colorKey"]);
          }
          return;
        }
      });

      this.canvas.on("selection:cleared", () => {
        this.$bus.$emit("hideMenuPopup");
      });

      this.canvas.on("mouse:down", e => {
        if (!e.transform) return;
        this.canvas.oClicked = true;
      });

      this.canvas.on("mouse:up", e => {
        if (!e.transform) return;
        const object = e.target;
        if (e.transform.corner !== "removeControl" && e.transform.corner !== "editControl" && object.type === "textbox") {
          if (!this.canvas.oMoving && this.canvas.oClicked) {
            this.$bus.$emit("showMenuPopup", {
              isText: true,
              textContent: object.text,
              sliderMinVal: object.minScaleLimit,
              sliderVal: object.scaleX,
              colorKey: object.colorKey,
              tab: "input",
            });
          }
        }
        this.canvas.oMoving = false;
        this.canvas.oClicked = false;
      });

      this.canvas.on("object:scaling", e => {
        if (e.target.type === "textbox") this.SET_SLIDER_VAL(e.target.scaleX);
        this.canvas.oMoving = true;

        // if (this.$route.matched[1].path.indexOf("artist") > -1) {
        //   const maxScaleWidth = this.clipPath.getScaledWidth();
        //   const maxScaleHeight = this.clipPath.getScaledHeight();

        //   const obj = e.target;
        //   const objWidth = obj.width;
        //   const objHeight = obj.height;

        //   const overRatio = objWidth / objHeight > maxScaleWidth / maxScaleHeight;
        //   if (overRatio) {
        //     if (obj.getScaledWidth() > maxScaleWidth) {
        //       obj.scaleX = maxScaleWidth / objWidth;
        //       obj.scaleY = maxScaleWidth / objWidth;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //     if (obj.getScaledHeight() > maxScaleHeight) {
        //       obj.scaleX = maxScaleHeight / objHeight;
        //       obj.scaleY = maxScaleHeight / objHeight;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //   } else {
        //     if (obj.getScaledHeight() > maxScaleHeight) {
        //       obj.scaleX = maxScaleHeight / objHeight;
        //       obj.scaleY = maxScaleHeight / objHeight;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //     if (obj.getScaledWidth() > maxScaleWidth) {
        //       obj.scaleX = maxScaleWidth / objWidth;
        //       obj.scaleY = maxScaleWidth / objWidth;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //   }
        // }
      });

      this.canvas.on("object:moving", e => {
        if (e.target.type === "textbox") this.$bus.$emit("hideMenuPopup");
        this.canvas.oMoving = true;
        // if (this.$route.matched[1].path.indexOf("artist") > -1) {
        //   this.detectBoundary(e);
        // }
        this.canvas.clearRedo();
      });

      this.canvas.on("object:removed", () => {
        const isEmpty = this.canvas.getObjects().filter(o => !o.isCarrierClass).length == 0;
        const objectIsEmpty = {
          ...this.objectIsEmpty,
          [this.perspective]: isEmpty,
        };
        this.SET_OBJECT_IS_EMPTY(objectIsEmpty);
        if (this.canvas.historyProcessing) return;
        this.canvas.clearRedo();
      });
      this.canvas.on("object:added", () => {
        const isEmpty = this.canvas.getObjects().filter(o => !o.isCarrierClass).length == 0;
        const objectIsEmpty = {
          ...this.objectIsEmpty,
          [this.perspective]: isEmpty,
        };
        this.SET_OBJECT_IS_EMPTY(objectIsEmpty);
        if (this.canvas.historyProcessing) return;
        this.canvas.clearRedo();
      });
      this.canvas.on("object:modified", () => {
        if (this.canvas.historyProcessing) return;
        this.canvas.clearRedo();
      });
      this.canvas.on("object:skewing", () => {
        if (this.canvas.historyProcessing) return;
        this.canvas.clearRedo();
      });
    },

    detectBoundary(e) {
      const obj = e.target;
      const objBoundary = obj.getBoundingRect();
      const canvasBoundary = this.clipPath.getBoundingRect();
      if (obj.currentHeight > canvasBoundary.height || obj.currentWidth > canvasBoundary.width) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (objBoundary.top <= canvasBoundary.top || objBoundary.left <= canvasBoundary.left) {
        obj.top = Math.max(obj.top, canvasBoundary.top + objBoundary.height / 2 / this.canvas.getZoom());
        obj.left = Math.max(obj.left, canvasBoundary.left + objBoundary.width / 2 / this.canvas.getZoom());
      }
      // bot-right corner

      const objBoundaryBottom = objBoundary.top + objBoundary.height;
      const objBoundaryRight = objBoundary.left + objBoundary.width;
      const canvasBoundaryBottom = canvasBoundary.top + canvasBoundary.height;
      const canvasBoundaryRight = canvasBoundary.left + canvasBoundary.width;

      if (objBoundaryBottom >= canvasBoundaryBottom || objBoundaryRight >= canvasBoundaryRight) {
        obj.top = Math.min(obj.top, canvasBoundaryBottom - objBoundary.height / 2 / this.canvas.getZoom());
        obj.left = Math.min(obj.left, canvasBoundaryRight - objBoundary.width / 2 / this.canvas.getZoom());
      }
    },

    //【ID1000281】生产输出高清图

    /*
     *  1. 设计图 2.带有衣服的预览图
     *  @param resolve 结束promise
     *
     */
    async exportCanvas(resolve) {
      if (!this.carrierConfig[this.perspective]) {
        //如果没有这一面，就return
        resolve();
        return;
      }

      const { boundWidth, boundHeight, boundX, boundY, carrierWidth } = this.carrierConfig[this.perspective]; // carrierConfig 是中台录入的数据, 拿取框宽度高度xy和载体宽度

      this.canvas.renderAll(); //在导出之前渲染一遍

      this.canvas.offHistory(); //关闭历史记录
      await this.resetHistoryCanvas(); //重置历史记录
      this.zoomIn(); // 必须放大来导出高清大图

      // 1. 导出设计图
      this.canvas.set({
        backgroundColor: "transparent", //背景图透明
      });

      this.clipPath.opacity = 0;
      this.bound.opacity = 0; // 设计框透明度设置为 0
      this.spec2 && (this.spec2.opacity = 0); // 如果有胸标 将其透明度设置为0
      this.carrierImage.opacity = 0; //底衣透明度设置为0

      // const multi = ((this.perspective === "back") || (this.perspective === "front")) ? 0.5 : 0.2;
      // const multi = 1;

      const originClipWidth = (boundWidth / carrierWidth) * this.canvas.width * this.canvas.getZoom(); // 中台提供的框宽cm/中台提供的载体宽度cm * canvas宽度px * canvas缩放值 来求得设备上的设计框宽px
      const originClipHeight = (boundHeight / carrierWidth) * this.canvas.width * this.canvas.getZoom();
      const originClipOffsetX = (boundX / carrierWidth) * this.canvas.width; // 中台提供的中点横向 (偏移量) cm/中台提供的载体宽度cm * canvas宽度px * canvas缩放值 来求得设备上的设计框宽px
      const originClipOffsetY = (boundY / carrierWidth) * this.canvas.width;

      const originClipLeft = this.canvas.width / 2 - originClipWidth / 2; // 根据上文算出
      const originClipTop = this.canvas.height / 2 - originClipHeight / 2;

      let outputCanvasWidth, outputCanvasHeight, outputCanvasLeft, outputCanvasTop;

      if (this.perspective === "back" || this.perspective === "front") {
        outputCanvasWidth = originClipWidth;
        outputCanvasHeight = originClipHeight;
        outputCanvasLeft = originClipLeft;
        outputCanvasTop = originClipTop;
      } else {
        outputCanvasWidth = originClipWidth;
        outputCanvasHeight = originClipHeight;
        outputCanvasLeft = originClipLeft;
        outputCanvasTop = originClipTop;
      }

      // const targetWidth = ((this.carrierConfig[this.perspective].boundWidth * 300) / 2.54) * multi; // 框宽度 * 300(ppi) / 2.54 (转成inch) * 0.5 (缩放系数来兼容手机导出)

      let outputCanvasImage = null;

      let multi;
      if (outputCanvasWidth * 8 > 3000) {
        multi = 3000 / outputCanvasWidth;
      } else {
        multi = 8;
      }

      if (!this.canvasIsEmpty()) {
        //【ID1000282】前后左右的Canvas按手机规格调整
        this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0); //放大8倍，因手机大小不同输出也有一些差异
        outputCanvasImage = this.canvas.toDataURL({
          width: outputCanvasWidth,
          height: outputCanvasHeight,
          left: outputCanvasLeft,
          top: outputCanvasTop,
          multiplier: multi,
          // multiplier: targetWidth / outputCanvasWidth,
          format: "png",
          crossOrigin: "Anonymous",
        });
        this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0.8);
      }

      this.carrierImage.opacity = 1;
      console.log(this.carrierImage.top, this.carrierImage.left);

      const outputPreviewImage = this.canvas.toDataURL({
        width: this.carrierImage.getScaledWidth() * this.canvas.getZoom(),
        height: this.carrierImage.getScaledHeight() * this.canvas.getZoom(),
        left: this.carrierImage.left - (this.carrierImage.getScaledWidth() * this.canvas.getZoom()) / 2 - originClipOffsetX * this.canvas.getZoom(),
        top: this.carrierImage.top - (this.carrierImage.getScaledHeight() * this.canvas.getZoom()) / 2 - originClipOffsetY * this.canvas.getZoom(),
        multiplier: 1600 / this.carrierImage.width,
        format: "png",
        crossOrigin: "anonymous",
      });

      this.clipPath.opacity = 1;
      this.bound.opacity = 0.5;
      this.spec2 && (this.spec2.opacity = 0.5);

      this.canvas.goOnHistory();

      const objs = this.canvas.getObjects();
      let ipId;
      if (this.$route.matched[1].path.indexOf("artist") != -1) {
        ipId = objs.filter(o => o.typeId == 5)[0]?.ipId || "";
      } else {
        ipId = "0";
      }
      const elementIds = objs
        .map(item => item.elementId)
        .filter(i => i)
        .join();

      // this.SET_OUTPUT_CANVAS_IMAGE(outputCanvasImage);
      // this.SET_OUTPUT_PREVIEW_IMAGE(outputPreviewImage);

      const canvasJSON = this.canvas.toDatalessJSON();

      canvasJSON.objects.forEach((object, index) => {
        if (object.defaultId === "leftSleeveImage" || object.defaultId === "rightSleeveImage") {
          canvasJSON.objects.splice(index, 1);
        }
      });

      canvasJSON.colorConfig = JSON.parse(JSON.stringify(this.getColorConfig));

      if (outputCanvasImage !== null) {
        const outputCanvasImages = { ...this.outputCanvasImages, [this.perspective]: outputCanvasImage };
        this.SET_OUTPUT_CANVAS_IMAGES(outputCanvasImages);
      }

      const outputPreviewImages = { ...this.outputPreviewImages, [this.perspective]: outputPreviewImage };
      const designJSONs = { ...this.designJSONs, [this.perspective]: JSON.stringify(canvasJSON) };
      const elementIdsObj = { ...this.elementIds, [this.perspective]: elementIds };
      const ipIdsObj = { ...this.ipIds, [this.perspective]: ipId };

      this.SET_OUTPUT_PREVIEW_IMAGES(outputPreviewImages);
      this.SET_DESIGN_JSONS(designJSONs);
      this.SET_ELEMENT_IDS(elementIdsObj);
      this.SET_IP_IDS(ipIdsObj);

      this.canvas.renderAll();
      this.zoom150x();
      resolve && resolve();
    },

    //上传的照片放在画布上 2023年2月1日15:37:46
    async addUploadedImage(url) {
      //【ID1000225】4.7 创作中心-用户-照片上传
      Toast.loading({
        message: "读取中...",
        forbidClick: true,
        duration: 0,
      });
      await new Promise(resolve =>
        fabric.Image.fromURL(
          // 使用oss参数强转为png
          url + "?x-oss-process=image/format,png",
          async img => {
            const basic = img.width > img.height ? img.width : img.height;
            img.set({
              left: this.bound.left, //【ID1000346】加入所有的设计元素，都按照载体的创作框居中
              top: this.bound.top, //【ID1000346】加入所有的设计元素，都按照载体的创作框居中
              originX: "center",
              originY: "center",
              scaleX: (100 / img.width).toFixed(2),
              scaleY: (100 / img.width).toFixed(2),
              backgroundColor: "transparent",
              defaultId: "customImage",
              typeId: 10,
              imgUrl: url,
            });
            // img.minScaleLimit = this.clipPath.width / 35 / basic;
            img.clipPath = this.clipPath; //剪贴蒙版

            let scale; //【ID1000340】【Bug转需求】IP创作中心图片展示比之前小 IP图3/4画布 贴子1/4画布
            if (img.width > img.height) {
              scale = (this.bound.width / this.canvas.getZoom() / img.width) * 0.75;
            } else {
              scale = (this.bound.height / this.canvas.getZoom() / img.height) * 0.75;
            }
            img.scale(scale);

            img.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              tr: false,
              editControl: false,
            });
            this.canvas.add(img);
            this.canvas.setActiveObject(img);

            this.CHANGE_IS_INTERCEPT(true);
            this.CHANGE_MENU_STATUS(true);
            resolve();
            Toast.clear();
          },
          { crossOrigin: "Anonymous" },
        ),
      );
      Toast.clear();
    },

    //保存素材2023年2月1日15:37:22

    async saveSnapshot() {
      if (this.canvasIsEmpty()) {
        //【ID1000282】前后左右的Canvas按手机规格调整
        Toast("请先创作");
        return;
      }

      Toast.loading({
        message: "保存中...",
        forbidClick: true,
        duration: 0,
      });

      const { boundWidth, boundHeight, carrierWidth } = this.carrierConfig[this.perspective]; //拿取框宽度高度xy和载体宽度

      this.canvas.renderAll();

      this.canvas.offHistory(); //关闭历史记录
      await this.resetHistoryCanvas(); //重置历史记录

      const isZoomIn = this.zoomToggle;

      this.zoomIn(); // 必须放大来导出高清大图

      this.bound.opacity = 0;
      this.spec2 && (this.spec2.opacity = 0);
      this.carrierImage.opacity = 0;

      const originClipWidth = (boundWidth / carrierWidth) * this.canvas.width * this.canvas.getZoom();
      const originClipHeight = (boundHeight / carrierWidth) * this.canvas.width * this.canvas.getZoom();

      const originClipLeft = this.canvas.width / 2 - originClipWidth / 2;
      const originClipTop = this.canvas.height / 2 - originClipHeight / 2;

      let outputCanvasWidth, outputCanvasHeight, outputCanvasLeft, outputCanvasTop;

      if (this.perspective === "back" || this.perspective === "front") {
        outputCanvasWidth = originClipWidth;
        outputCanvasHeight = originClipHeight;
        outputCanvasLeft = originClipLeft;
        outputCanvasTop = originClipTop;
      } else {
        outputCanvasWidth = this.clipPath.width * this.canvas.getZoom();
        outputCanvasHeight = this.clipPath.height * this.canvas.getZoom();
        outputCanvasLeft = this.clipPath.left - outputCanvasWidth / 2;
        outputCanvasTop = this.clipPath.top - outputCanvasHeight / 2;
      }

      let multi;
      if (outputCanvasWidth * 8 > 3000) {
        multi = 3000 / outputCanvasWidth;
      } else {
        multi = 8;
      }
      this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0); //放大8倍，因手机大小不同输出也有一些差异
      const outputCanvasImage = this.canvas.toDataURL({
        width: outputCanvasWidth,
        height: outputCanvasHeight,
        left: outputCanvasLeft,
        top: outputCanvasTop,
        multiplier: multi,
        // multiplier: targetWidth / outputCanvasWidth,
        format: "png",
        crossOrigin: "Anonymous",
      });

      this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0.8);
      this.bound.opacity = 0.5;
      this.spec2 && (this.spec2.opacity = 0.5);
      this.carrierImage.opacity = 1;

      this.canvas.goOnHistory();
      if (isZoomIn) {
        this.zoomIn();
      } else {
        this.zoom150x();
      }

      const url = await this.uploadImage(outputCanvasImage);
      const result = await saveUserElements({ path: url });
      console.log(result);
      this.$bus.$emit("refreshMatterData");
      Toast.success("已保存至我的素材");
    },

    /**
     * 素材上传2023年2月1日15:29:33\
     * @param String dataUrl
     */

    async uploadImage(dataUrl) {
      const fileBlob = dataURLtoBlob(dataUrl);
      const res = await uploadOSS(fileBlob); //oss的原始链接产生的流量是要收费的/价格昂贵, 这边用的cdn回源流量包产生的费用较小,因此需要拼接一下cdn的endpoint生成新的链接.
      console.log(res, res.options.endpoint + "/" + res.result.name);
      return res.options.endpoint + "/" + res.result.name;
    },

    //将扣完的图片替换 2023年2月1日15:29:39
    replaceUploadedImage(url) {
      // 【ID1000264】4.13 创作中心-用户-自动抠图 替换为扣完的图片
      const image = this.canvas.getActiveObject(); //拿到当前选中的图片
      console.log(url);
      Toast.loading({
        message: "读取中...",
        forbidClick: true,
        duration: 0,
      });

      image.setSrc(
        url,
        () => {
          //第二个参数为回调, 将canvas重新渲染
          this.canvas.renderAll();
          Toast.clear();
        },
        { crossOrigin: "Anonymous" }, // 设置跨域
      ); // 替换图片src
    },
  },
  created() {},
  async mounted() {
    console.log("mounted");
    Toast.loading({
      message: "Loading...",
      forbidClick: true,
      overlay: true,
      duration: 0,
    });
    // this.$nextTick(async () => {
    //   setTimeout(async () => {
    await this.init();
    if (this.perspective == "front" && this.stickerInfo) {
      this.addSvg(this.stickerInfo);
      this.SET_STICKER_INFO(null);
    }
    Toast.clear();
    //   }, 1000);
    // });
  },
  unmounted() {
    this.destroyBus();
  },
  activated() {
    console.log("activated");
    this.destroyBus();
    this.initBus();
    if (this.$route.query.fromAvatar) {
      if (this.mcId) {
        this.canvas &&
          this.canvas.getObjects().forEach(o => {
            if (o.isAvatar) {
              this.canvas.remove(o);
            }
          });
      }
      const options = { fromAvatar: true, ...this.getAvatarColor, type: 1 };
      if (this.perspective === this.currentPerspective) this.addSvg({ url: this.getAvatarSvg, options });
    }
    if (this.perspective == this.currentPerspective && this.stickerInfo) {
      this.addSvg(this.stickerInfo);
      this.SET_STICKER_INFO(null);
    }
  },
  deactivated() {
    this.destroyBus();
  },
  computed: {
    ...mapGetters(["getColorConfig", "getColorLibrary", "getImage", "getAvatarSvg", "getAvatarColor", "getSleeveImages"]),
    ...mapState([
      "activeColorKey",
      "activeColor",
      "templateId",
      "designJSON",
      "fontList",
      "mcConfig",
      "mcId",
      "carrierConfig",
      "elementIds",
      "ipIds",
      "outputCanvasImages",
      "outputPreviewImages",
      "designJSONs",
      "currentIpId",
      "zoomToggle",
      "currentPerspective",
      "stickerInfo",
      "objectIsEmpty",
      "productId",
      "channel",
      "uploadedImage",
    ]),
  },
  components: {},
};
</script>

<style></style>
