<template>
  <div class="container">
    <my-popup :showMask="false" :checked="searchStatus">
      <my-search :searchitem="isSearchValue" @handleCancel="cancel" @setSearchValue="showSearchValue" style="margin-top: 16px"></my-search>
      <template v-if="showStatus === '1'">
        <div class="search-history">
          <div class="history-title">History search</div>
          <div @click="clear" class="clear-btn">Clear History</div>
        </div>
        <div class="history-box">
          <div v-for="(item, index) in getHistoryList" :key="index" @click="getSearchValue(item)" class="history-list-item">
            {{ item }}
          </div>
        </div>
      </template>
      <template v-if="showStatus === '2'">
        <div class="listNum">{{ searchNum }}条相关搜索结果</div>
        <search-result :dataListInfo="dataList"></search-result>
      </template>
    </my-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { search, getShopSearchHistory, getClearSearch } from "@/api/app.js";
import mySearch from "@/components/common/my-search/my-search.vue";
import myPopup from "@/components/common/myPopup/myPopup.vue";
import searchResult from "@/components/content/searchResult/searchResult.vue";

const HIS_LIST = "1";
const RESULT_LIST = "2";
export default {
  name: "searchBox",
  data() {
    return {
      showStatus: HIS_LIST,
      historyList: [],
      // searchType: "",
      isSearchValue: "",
      searchNum: "",
      dataList: [],
      historyCode: "",
      isHistoryType: "",
    };
  },
  components: {
    mySearch,
    myPopup,
    searchResult,
  },

  computed: {
    ...mapState(["searchType", "searchStatus", "historyCodeType", "avatarType", "isChild"]),
    getHistoryList() {
      return this.historyList.slice(0, 14);
    },
  },
  mounted() {
    let currentPath = this.$route.path;
    if (currentPath.indexOf("/artist") > -1) {
      this.isHistoryType = "ip";
    } else {
      this.isHistoryType = "element";
    }
  },
  watch: {
    // 监听popu的改变
    searchStatus(newValue) {
      if (newValue) {
        this.getShopSearchHistory();
      }
    },
  },

  methods: {
    // 提交搜索的内容
    showSearchValue(value) {
      this.showStatus = RESULT_LIST;
      this.isSearchValue = value;
      if (value == "") return;
      this.getSearchResult();
    },
    // 搜索历史点击
    getSearchValue(item) {
      this.showStatus = RESULT_LIST;
      this.isSearchValue = item;
      this.getSearchResult();
    },
    //搜索结果
    getSearchResult() {
      let searchTypeStatus;
      this.dataList = [];

      if (this.searchType === "artist") {
        searchTypeStatus = 3;
      } else if (this.searchType === "sticker") {
        searchTypeStatus = 20;
      }
      search({
        historyCode: this.isHistoryType,
        type: searchTypeStatus,
        keyword: this.isSearchValue,
        elementType: 20,
        is_avatar: this.avatarType ? 1 : 0,
        is_child: this.isChild ? this.isChild : 0,
      })
        .then(res => {
          this.dataList = res.data.data.list;
          this.searchNum = this.dataList.length;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //取消重置状态
    cancel() {
      this.showStatus = HIS_LIST;
    },
    // 获取搜索历史
    getShopSearchHistory() {
      getShopSearchHistory({
        historyCode: this.isHistoryType,
      }).then(res => {
        this.historyList = res.data.data.list;
      });
    },

    // 清除搜索记录
    clear() {
      Dialog.confirm({
        message: "Clear history记录，确定继续？",
      })
        .then(() => {
          getClearSearch({
            historyCode: this.isHistoryType,
          }).then(res => {
            if (res.status === 200) {
              this.getShopSearchHistory();
            }
          });
          Toast("清除成功!");
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  z-index: 999;
  background-color: #ffffff;
  width: 100%;
  .search-history {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .history-title {
      margin-top: 16px;
      font-size: 28rpx;
      color: #000000;
      font-weight: 500;
    }
    .clear-btn {
      margin-top: 16px;
      font-size: 12px;
      font-weight: 12px;
    }
  }
  .history-box {
    padding: 16px 12px 0 12px;
    .history-list-item {
      box-sizing: content-box;
      margin-right: 12px;
      margin-bottom: 12px;
      font-size: 12px;
      padding: 0 20px;
      display: inline-block;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-radius: 16px;
      border: 1px solid #e3e4e5;
    }
  }
  .listNum {
    color: #ababab;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 8px 0 16px 0;
  }
}
</style>
