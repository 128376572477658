<template>
  <div>
    <div
      v-if="mcConfig.is_mass_customization != 0 || mcMenu == 1"
      class="absolute bottom-16 right-3 flex flex-col space-y-2 duration-150 origin-bottom"
      :class="{ 'scale-0 overflow-hidden': !toolsShow }"
    >
      <div v-if="!isArtist" @click="handleClick('undo')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-undo text-sm"></i>
      </div>
      <div v-if="!isArtist" @click="handleClick('redo')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-redo text-sm"></i>
      </div>
      <div v-if="!isArtist" @click="handleClick('sendBackwards')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-backward text-sm"></i>
      </div>
      <div v-if="!isArtist" @click="handleClick('bringForward')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-forward text-sm"></i>
      </div>
      <!-- <div v-if="!isArtist" @click="handleClick('addUploadedImage')"
        class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-camera text-sm"></i>
      </div> -->

      <div v-if="!isArtist" @click="saveSnapshot()" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <img class="object-contain w-4 h-4" src="@/static/folder.png" alt="" />
      </div>

      <div @click="handleClick('setZoomToggle')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-sm" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>
    <div v-else class="absolute bottom-1/3 -translate-y-1/2 right-3 flex flex-col space-y-2">
      <div @click="handleClick('setZoomToggle')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-sm" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>

    <!-- 【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏-->

    <div v-if="perspectives.length > 1" class="absolute bottom-3 right-16 flex space-x-2 duration-150 origin-right" :class="{ 'scale-0 overflow-hidden': !toolsShow }">
      <div
        v-for="(item, index) in perspectives"
        :key="index"
        @click="handlePerspective(item.perspective, index)"
        class="relative w-9 h-9 text-xs bg-white rounded-full flex justify-center items-center border-gray-300 border"
      >
        <div
          v-if="selectedPerspective === item.perspective"
          class="absolute w-full h-full text-sm flex justify-center items-center border rounded-full scale-125 z-10"
          style="border-color: #23d59c"
        ></div>
        {{ item.name }}
      </div>
    </div>

    <div class="absolute bottom-3 right-3 rounded-full w-10 h-10 flex justify-center items-center bg-white" @click="toggleTools">
      <img v-if="toolsShow" src="../static/tool.png" alt="" />
      <img v-else src="../static/tool_closed.png" alt="" />
    </div>

    <div style="display: none">
      <input type="file" accept="image/*" ref="uploadImage" @change="handleFileChange" style="display: none" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { postUploadImage, uploadOSSImg } from "@/api/app";
import { Toast } from "vant";
import { compressedImageFuc } from "@/utils/imgUtils";
import { awaitExpression } from "@babel/types";

export default {
  name: "ToolBar",
  data() {
    return {
      isArtist: false, //是否为艺术家
      selectedPerspective: "front", //选中的面
      toolsShow: false, //工具栏是否弹出
    };
  },
  computed: {
    ...mapState(["carrierConfig", "zoomToggle", "mcConfig", "mcMenu"]),
    perspectives() {
      const perspectives = [
        { perspective: "front", name: "F" },
        { perspective: "back", name: "B" },
        { perspective: "left", name: "L" },
        { perspective: "right", name: "R" },
      ];

      const res = [];

      perspectives.forEach(item => {
        //载体有几个面
        if (!this.carrierConfig[item.perspective]) {
          return;
        }
        res.push(item);
      });
      return res;
    },
  },
  mounted() {
    this.$bus.$on("callCamera", () => {
      this.handleClick("addUploadedImage");
    });
    this.isArtist = this.$route.matched[1].path.indexOf("artist") !== -1;
  },
  unmounted() {
    this.$bus.$off("callCamera");
  },
  methods: {
    ...mapMutations(["SET_CURRENT_PERSPECTIVE", "SET_ZOOM_TOGGLE", "CHANGE_MENU_STATUS", "SET_UPLOADED_IMAGE"]),
    //【ID1000258】创作中心微调
    toggleTools() {
      this.toolsShow = !this.toolsShow;
    },
    saveSnapshot() {
      this.$bus.$emit(this.$store.state.currentPerspective + ":saveSnapshot");
    },
    handleClick(event) {
      if (event === "setZoomToggle") this.SET_ZOOM_TOGGLE(!this.zoomToggle);
      if (event === "addUploadedImage") {
        this.$refs.uploadImage.click();
        return;
      }
      this.$bus.$emit(this.$store.state.currentPerspective + ":" + event);
    },
    async handleFileChange(image) {
      const file = image.target.files[0];
      console.log(file);
      let fileName = file.name.toLowerCase();
      const isImage = /.(jpg|png|jpeg)/.test(fileName);
      if (!isImage) {
        //【ID1000342】抠图使用JPG或PNG到阿里云
        Toast({
          message: "请上传jpg或png格式的图片",
          forbidClick: true,
          duration: 1000,
        });
        this.$refs.uploadImage.value = "";
        return;
      }

      const url = await this.uploadImage(file);

      this.$bus.$emit(this.$store.state.currentPerspective + ":addUploadedImage", url);
    },

    //【ID1000268】4.9.1 创作中心-用户-保存图片
    async uploadImage(image) {
      if (this.$refs.uploadImage.value == "") return;
      // const formData = new FormData();
      // formData.append("file", image, image.name);
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
      });

      let maxSize = 15 * 1024 * 1024;
      let res;
      if (image.size >= maxSize) {
        const newImg = await compressedImageFuc(image);
        res = await uploadOSSImg(newImg);
      } else {
        res = await uploadOSSImg(image);
      }
      // const res = await uploadOSSImg(image);
      console.log(res, res.options.endpoint + "/" + res.result.name);
      Toast.clear();
      this.$refs.uploadImage.value = "";
      this.SET_UPLOADED_IMAGE(res.result.url + "?x-oss-process=image/format,png");
      return res.options.endpoint + "/" + res.result.name;
    },
    //【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏
    handlePerspective(event, index) {
      this.$bus.$emit("hideMenuPopup"); // 隐藏所有弹窗
      this.selectedPerspective = event;
      this.SET_CURRENT_PERSPECTIVE(event); // 设置当前的面
      this.$bus.$emit(event);
      this.$emit("switchPerspective", index); //切换到当前面的索引
      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        //在正面背面渲染左袖右袖
        this.$bus.$emit("left:exportPerspectiveCanvas");
        this.$bus.$emit("right:exportPerspectiveCanvas");
      }

      this.$bus.$emit("front:renderPerspectiveCanvas");
      this.$bus.$emit("back:renderPerspectiveCanvas");
      // 放大每个面
      this.$bus.$emit("front:zoom150x");
      this.$bus.$emit("back:zoom150x");
      this.$bus.$emit("left:zoom150x");
      this.$bus.$emit("right:zoom150x");
    },
  },
};
</script>

<style></style>
