<template>
  <div>
    <div class="absolute bottom-3 left-3 flex justify-center items-center py-2 px-3 bg-white rounded-full space-x-4" v-if="!isArtist && carrierConfig.is_knitting == '1'">
      <div class="flex justify-center items-center space-x-3">
        <i class="iconfont icon-colorpad color-pad text-2xl leading-none"></i>
        <div class="relative w-6 h-6 leading-none" @click="handleColor('defaultColor')">
          <div
            class="color-item w-full h-full rounded-full relative overflow-hidden"
            :style="{
              backgroundColor: getColorConfig.defaultColor.color || 'none',
            }"
          >
            <ColorPlaceholder v-show="!getColorConfig.defaultColor.color" />
          </div>
          <img class="absolute z-20 w-full h-full left-0 top-0 scale-125" :src="lockIcon" alt="" />
          <div
            v-show="selectMode && activeColorKey === 'defaultColor'"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border-2 border-black rounded-full"
            :style="{ borderColor: '#23d59c' }"
          ></div>
          <div
            v-show="!(selectMode && activeColorKey === 'defaultColor')"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border border-black rounded-full"
            :style="{ borderColor: '#000000' }"
          ></div>
        </div>
      </div>
      <div class="divide-v h-3"></div>
      <div class="flex justify-center items-center space-x-3">
        <div class="relative w-6 h-6 leading-none" @click="handleColor('primaryColor')">
          <div
            class="color-item w-full h-full rounded-full relative overflow-hidden"
            :style="{
              backgroundColor: getColorConfig.primaryColor.color || 'none',
              borderColor: '#e2e2e2',
            }"
          >
            <ColorPlaceholder v-show="!getColorConfig.primaryColor.color" />
          </div>
          <img v-show="getColorConfig.primaryColor.isLocked" class="absolute z-20 w-full h-full left-0 top-0 scale-125" :src="lockIcon" alt="" />

          <div
            v-show="selectMode && activeColorKey === 'primaryColor'"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border-2 border-black rounded-full"
            :style="{ borderColor: '#23d59c' }"
          ></div>

          <div
            v-show="!(selectMode && activeColorKey === 'primaryColor') && getColorConfig.primaryColor.isLocked"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border border-black rounded-full"
            :style="{ borderColor: '#000000' }"
          ></div>
        </div>
        <div class="relative w-6 h-6 leading-none" @click="handleColor('secondaryColor')">
          <div
            class="color-item w-full h-full rounded-full relative overflow-hidden"
            :style="{
              backgroundColor: getColorConfig.secondaryColor.color || 'none',
              borderColor: '#e2e2e2',
            }"
          >
            <ColorPlaceholder v-show="!getColorConfig.secondaryColor.color" />
          </div>
          <img v-show="getColorConfig.secondaryColor.isLocked" class="absolute z-20 w-full h-full left-0 top-0 scale-125" :src="lockIcon" alt="" />

          <div
            v-show="selectMode && activeColorKey === 'secondaryColor'"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border-2 border-black rounded-full"
            :style="{ borderColor: '#23d59c' }"
          ></div>
          <div
            v-show="!(selectMode && activeColorKey === 'secondaryColor') && getColorConfig.secondaryColor.isLocked"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border border-black rounded-full"
            :style="{ borderColor: '#000000' }"
          ></div>
        </div>
        <div class="relative w-6 h-6 leading-none" @click="handleColor('tertiaryColor')">
          <div
            class="color-item w-full h-full rounded-full relative overflow-hidden"
            :style="{
              backgroundColor: getColorConfig.tertiaryColor.color || 'none',
              borderColor: '#e2e2e2',
            }"
          >
            <ColorPlaceholder v-show="!getColorConfig.tertiaryColor.color" />
          </div>
          <img v-show="getColorConfig.tertiaryColor.isLocked" class="absolute z-20 w-full h-full left-0 top-0 scale-125" :src="lockIcon" alt="" />

          <div
            v-show="selectMode && activeColorKey === 'tertiaryColor'"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border-2 border-black rounded-full"
            :style="{ borderColor: '#23d59c' }"
          ></div>
          <div
            v-show="!(selectMode && activeColorKey === 'tertiaryColor') && getColorConfig.tertiaryColor.isLocked"
            class="absolute z-10 w-full h-full left-0 top-0 scale-125 border border-black rounded-full"
            :style="{ borderColor: '#000000' }"
          ></div>
        </div>
      </div>
    </div>
    <MenuPopup
      v-model:show="showPopup"
      :selectedColorKey="selectedColorKey"
      :isText="isText"
      :tab="tab"
      :textContent="textContent"
      :sliderMinVal="sliderMinVal"
      :sliderVal="sliderVal"
      @hidePopup="hidePopup"
      @setText="setTextContent"
      @setTab="setTab"
    />
  </div>
</template>

<script>
import ColorPlaceholder from "./ColorPlaceholder.vue";
import MenuPopup from "./MenuPopup.vue";
import lockIcon from "@/assets/images/icons/lock.png";
import { Toast } from "vant";

import { mapGetters, mapMutations, mapState } from "vuex";
import { stringifyQuery } from "vue-router";

export default {
  name: "ColorPicker",
  data() {
    return {
      showPopup: false, //弹窗是否拉起
      selectMode: true, //当前是否是选中模式
      selectedColorKey: "primaryColor", //选中的颜色
      isText: false, //是否为文字
      textContent: "", //文字内容
      sliderMinVal: 0, //滑条最小值
      sliderVal: 100, //滑条数值
      lockIcon, //锁定图标
      isArtist: false, //是否为艺术家入口
      tab: "color", // 初始化的tab
    };
  },
  methods: {
    ...mapMutations(["SET_COLOR_CONFIG", "SET_ACTIVE_COLOR_KEY", "CHANGE_MENU_STATUS", "SET_ACTIVE_COLOR", "CHANGE_IS_INTERCEPT", "CHANGE_MENU_STATUS"]),
    setObjectColor() {
      this.$emit("objectColor", {
        colorConfig: this.newColorConfig,
        currentColor: this.currentColorType,
      });
    },
    handleShowPopup(e) {
      const isText = e?.isText;
      const textContent = e?.textContent || "";
      const sliderMinVal = e?.sliderMinVal || 0;
      const sliderVal = e?.sliderVal;
      const colorKey = e?.colorKey;
      const tab = e?.tab || "color";
      console.log(colorKey, e);
      this.tab = tab;
      this.isText = isText;
      this.textContent = textContent;
      this.sliderMinVal = sliderMinVal;
      this.sliderVal = sliderVal;
      //点击菜单路由后当添加一个默认的字体
      if (textContent === "" && tab === "input") {
        let defaultFontFamily = this.fontList[0];
        let url, options;
        url = defaultFontFamily.value;
        options = {
          fontFamily: defaultFontFamily.font_family,
          elementId: defaultFontFamily.id,
          ipId: defaultFontFamily.ip_id || 0,
          type: 2,
        };
        this.$bus.$emit(this.$store.state.currentPerspective + ":addFont", { url, options });
      }
      this.showPopup = true;
      this.selectMode = true;
      colorKey && this.SET_ACTIVE_COLOR_KEY(colorKey);
      this.selectedColorKey = colorKey;
      this.checkColorConfig();
      this.CHANGE_MENU_STATUS(true);
      this.$bus.$on("changeColorConfig", this.changeColorConfig);
    },
    hidePopup() {
      // this.CHANGE_IS_INTERCEPT("");
      // this.CHANGE_MENU_STATUS(false);
      this.showPopup = false;
      this.selectMode = true;

      this.$bus.$off("changeColorConfig");
    },
    handleColor(colorKey) {
      // if (this.getColorConfig[colorKey].isLocked && this.selectMode) return;
      // if (this.selectMode) {
      // if (colorKey === "defaultColor") return;

      this.selectedColorKey = colorKey;
      this.SET_ACTIVE_COLOR_KEY(colorKey);
      // return;
      // }
      if (this.getColorConfig[colorKey].color) {
        this.$bus.$emit(this.$store.state.currentPerspective + ":setObjectColor", { colorKey });
      }
    },
    changeColorConfig({ color, value }) {
      console.log("changing");
      if (this.getColorConfig[this.activeColorKey].isLocked) {
        Toast.fail("选中色已被锁定");
        return;
      }
      const colorConfig = { ...this.getColorConfig };
      colorConfig[color] = value;
      this.SET_COLOR_CONFIG(colorConfig);
      if (this.carrierConfig.is_knitting == "1") {
        this.$bus.$emit(this.$store.state.currentPerspective + ":setObjectColor", { colorKey: this.activeColorKey });
      } else {
        this.SET_ACTIVE_COLOR(value.color);
        this.$bus.$emit(this.$store.state.currentPerspective + ":setObjectColor", value.color);
      }
    },
    checkColorConfig() {
      // for (let key in this.getColorConfig) {
      //   if (key === "defaultColor") continue;
      //   if (!this.getColorConfig[key].color) {
      //     this.selectedColorKey = key;
      //     this.activeColorKey = key;
      //     break;
      //   } else if (!this.getColorConfig[key].isLocked) {
      //     this.selectedColorKey = key;
      //     this.activeColorKey = key;
      //     break;
      //   } else {
      //     this.selectedColorKey = null;
      //     this.activeColorKey = null;
      //   }
      // }
    },
    setSelectActive(colorKey) {
      this.SET_ACTIVE_COLOR_KEY(colorKey);
    },
    setTextContent(textContent) {
      this.textContent = textContent;
    },
    setTab(tab) {
      this.tab = tab;
      console.log(tab);
    },
  },
  computed: {
    ...mapGetters(["getColorConfig"]),
    ...mapState(["activeColorKey", "is_knitting", "carrierConfig", "fontList"]),
  },
  mounted() {
    this.isArtist = this.$route.matched[1].path.indexOf("artist") !== -1;
    this.$bus.$on("showMenuPopup", this.handleShowPopup);
    this.$bus.$on("hideMenuPopup", this.hidePopup);
    this.$bus.$on(this.$store.state.currentPerspective + ":setTextContent", this.setTextContent);
  },
  unmounted() {
    this.$bus.$off("showMenuPopup");
    this.$bus.$off("hideMenuPopup");
    this.$bus.$off(this.$store.state.currentPerspective + ":setTextContent");
  },
  components: { ColorPlaceholder, MenuPopup },
};
</script>

<style>
.color-pad {
  color: transparent;
  background: linear-gradient(180deg, #a19dff 0%, rgba(255, 215, 169, 0.6) 100%);
  background-clip: text;
}

.color-item {
  border: 1px solid #e2e2e2;
}

.divide-v {
  width: 1px;
  background: #e2e2e2;
}

.vectors {
  transform: rotate(45deg);
}

.vector {
  background: #ababab;
  width: 1px;
}
</style>
