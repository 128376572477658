<template></template>
<script>
export default {
  name: "MenuContainer",
  data() {
    return {
      isShow: false, //控制打开和关闭
      checkedCurrentIndex: 0, //打开是左边选中的index
      albumList: [], //每个type的全部数据
      currentAlbumNum: 0, //关闭时展示的数据的index
      getOneAlbum: {}, //关闭时的一条数据
      collectList: [], //收藏的数据
      typeStatus: 3, //默认调用的专辑的参数
      mainType: 0, //type的状态
      scrollY: 0,
      rightHeight: [], //右边高度的数组
      leftHeight: [], //左边高度的数组
    };
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.change-icon {
  width: 12px;
  height: 6px;
  .arrow_icon {
    width: 12px;
    height: 6px;
  }
}
.font-sty {
  color: #ababab;
  font-size: 10px;
  line-height: 18px;
  padding-left: 2px;
  display: inline-block;
  width: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chang-height {
  transition: height 0.2s ease;
}
.list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  overflow: hidden;
  background-color: #f5f5f5;
}
.scroll-container {
  background-color: #f5f5f5;
  height: 268px;
  width: 62px;
}
.img_box {
  background-color: #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
}
</style>
