<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.matched[0]?.name" v-if="$route.meta.keepAlive"></component>
    </keep-alive>
    <component :is="Component" :key="$route.matched[0]?.name" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script>
import { wxConfig } from "./api/app";
import { mapActions, mapMutations } from "vuex";
import { getItem } from "@/utils/utils";
import VConsole from "vconsole";

export default {
  name: "App",
  data() {
    return {
      hasQuery: false,
    };
  },
  async created() {
    await wxConfig();

    document.addEventListener("UniAppJSBridgeReady", function () {
      // eslint-disable-next-line no-undef
      uni.getEnv(function (res) {
        console.log("当前环境：" + JSON.stringify(res));
      });
      // eslint-disable-next-line no-undef
      uni.postMessage({
        data: {
          action: "postMessage",
        },
      });
      window.postMessage({
        data: {
          action: "postMessage",
        },
      });
    });
    !(process.env.VUE_APP_ENV === "production") && new VConsole();

    // if(!this.hasQuery) {
    //   this.initQuery();
    // }
  },
  watch: {
    $route: {
      async handler() {
        if (!this.hasQuery) {
          await this.initQuery();
          this.hasQuery = true;
        }
        if (this.$route.path == "/sticker" && !this.$store.state.otherMenu) {
          if (this.$store.state.menuStatus) {
            this.$store.commit("CHANGE_MENU_STATUS", false);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setQueryString: "SET_QUERY_STRING",
      CHANGE_MENU_STATUS: "CHANGE_MENU_STATUS",
    }),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig"]),
    async initQuery() {
      console.log("route!", this.$route);
      const { query } = this.$route;

      if (query) {
        // let config = {
        //   carrier_id: query.carrier_id,
        //   store_name: query.store_name,
        //   price: query.price,
        //   image: query.image,
        //   value1: query.value1,
        //   value2: query.value2,
        //   token: query.token,
        //   stickerId: query.sticker_id,
        //   color_id: query.color_id,
        //   x1: query.x1,
        //   y1: query.y1,
        //   x2: query.x2,
        //   y2: query.y2,
        //   carrierWidth: query.carrierWidth,
        //   is_knitting: query.is_knitting,
        //   avatarType: query.avatarType,
        //   unique: query.unique,
        //   isTemplate: query.is_template ? query.is_template : null,
        //   templateId: query.template_id ? query.template_id : null,
        //   // mass customization id
        //   mcId: query.mc_id ? query.mc_id : null,
        // };

        const config = {
          productId: query.design_product_id || null,
          carrier_id: query.carrier_id || getItem("carrier_id"),
          // token: query.token || getItem("token"),
          token: "Bearer visitor",

          stickerId: query.sticker_id || undefined,
          avatarType: query.avatarType || undefined,
          unique: query.unique || getItem("unique"),
          templateId: query.template_id ? query.template_id : null,
          mcId: query.mc_id ? query.mc_id : null,
          mcMenu: query.mc_menu ? query.mc_menu : null,
          extAvatarId: query.ext_avatar_id,
          channel: query.channel || getItem("channel"),
          isChild: query.is_child || getItem("isChild"),
          seriesId: query.series_id,
          isIndex: query.is_index || getItem("isIndex"),
          actName: query.act_name || getItem("actName"),
          designProductId: query.design_product_id,
          addChannel: query.ad_ch ? query.ad_ch : null,
          addName: query.ad_na ? query.ad_na : null,
          currentCateId: query.cate_id,
        };
        console.log("config", config);

        if (config.addChannel) {
          console.log("记录广告来源", config.addChannel, config.addName);
          window._hmt.push(["_trackEvent", `add_channel:${config.addChannel}`, `add_channel:${config.addChannel},add_name:${config.addName}`]);
        }
        this.setQueryString(config);
        Object.keys(config).forEach(key => {
          if (config[key] == undefined || config[key] == null) {
            if (localStorage.getItem(key)) {
              window.localStorage.removeItem(key);
            }
            delete config[key];
            return;
          }
          localStorage.setItem(key, config[key]);
        });
      }
      await this.fetchColorLibrary();
      await this.fetchCarrierConfig();
    },
  },
};
</script>
<style lang="scss">
html,
body {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    // font-size: 35px;
  }

  // font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
