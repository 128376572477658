<template>
  <div class="container">
    <better-scroll ref="scrollRef" :probeType="3" class="content">
      <ip-series-info v-if="searchType === 'artist'" :seriesList="dataListInfo"></ip-series-info>
      <element-info-list v-if="searchType === 'sticker'" :dataList="dataListInfo"></element-info-list>
      <div style="height: 20px"></div>
    </better-scroll>
  </div>
</template>

<script>
import elementInfoList from "@/components/content/elementInfoList/elementInfoList.vue";
import ipSeriesInfo from "@/components/content/ipSeriesInfo/ipSeriesInfo.vue";
import betterScroll from "@/components/common/betterScroll.vue";
import { mapState } from "vuex";

export default {
  name: "searchResult",
  components: {
    betterScroll,
    ipSeriesInfo,
    elementInfoList,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState(["searchType"]),
  },
  mounted() {
    setTimeout(() => {
      console.log(this.$refs.scrollRef);
    }, 3000);
  },
  props: {
    dataListInfo: {
      type: Array,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 244px;
}
</style>
