/* eslint-disable */
import axios from "axios";
import { HTTP_REQUEST_URL, api, HTTP_REQUEST_URL_AI, HTTP_REQUEST_URL_NODE, apiAi } from "../config/api";
import store from "../store";
import OSS from "ali-oss";
import md5 from "md5";

axios.interceptors.request.use(res => {
  let isToken = store.state.token;

  if (!isToken) {
    isToken = localStorage.getItem("token");
  }

  const httpToken = isToken;

  res.headers["authorization"] = httpToken;
  res.headers["authori-zation"] = httpToken;
  return res;
});

// const config = {
// 	headers: {
// 		"Content-Type": "multipart/form-data",
// 		"Access-Token": token,
// 		//因为我的请求内容只有file，如果formdata复杂的话，建议参考上面的链接
// 		transformRequest: [data => data]
// 		}
// 	}

export const wxConfig = async () => {
  const { data } = await axios.get(`${HTTP_REQUEST_URL}/wechat/config`, {
    params: {
      url: window.location.href.split("#")[0],
    },
  });
  if (data.status === 200) {
    wx.config({ ...data.data });
  } else {
    console.log("获取微信配置失败");
  }
};

const HTTP = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL}${api}`, params, options),
  delete: async (api, params, options) => await axios.delete(`${HTTP_REQUEST_URL}${api}`, params, options),
};

const HTTP_NODE = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL_NODE}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL_NODE}${api}`, params, options),
};

const HTTPAI = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL_AI}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL_AI}${api}`, params, options),
};

async function initOSS() {
  const res = await HTTP.get(api.get_OSS_TOKEN);
  const { data } = res.data;
  const options = {
    endpoint: data.endpoint, // mp-oss.tootools.cn
    savePath: data.savePath,
  };
  delete data.endpoint;
  delete data.savePath;
  const client = new OSS(data);
  client.savePath = options.savePath;
  console.log(client);
  return { client, options };
}

export async function uploadOSS(file) {
  const { client, options } = await initOSS();
  const fullPath = `${client.savePath}/${file.name}`;
  const d = new Date();
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const hour = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const name = `${md5(fullPath).slice(0, 5)}${d.getFullYear()}${month}${day}${hour}${d.getMinutes()}${d.getSeconds()}${parseInt(Math.random(0, 1) * 1000)}`;
  console.log(`${options.savePath}`);

  const result = await client.put(`${options.savePath}/${name}.png`, file);
  return { result, options };
}

export async function uploadOSSImg(file) {
  const { client, options } = await initOSS();
  const fullPath = `store/comment/${file.name}`;
  const type = file.type.split("/")[1];
  const d = new Date();
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const hour = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const name = `${md5(fullPath).slice(0, 5)}${d.getFullYear()}${month}${day}${hour}${d.getMinutes()}${d.getSeconds()}${parseInt(Math.random(0, 1) * 1000)}`;
  console.log(`${options.savePath}`);
  const result = await client.put(`${options.savePath}/${name}.${type}`, file);
  return { result, options };
}

//贴纸
export function getElement(params) {
  return HTTP.get(api.get_element, params);
}

//艺术家
export function getSeries(params) {
  return HTTP.get(api.get_series, params);
}

//搜索 收藏 (创作中心我的收藏也可以用)
export function search(params) {
  params.is_knitting = store.state.carrierConfig.is_knitting;
  return HTTP.get(api.search, params);
}
// 收藏

export function setSeriesCollect(params) {
  return HTTP.post(api.post_setCollection, params);
}

//获取载体详情列表
export function getProductCarrierDetailList(params) {
  return HTTP.get(api.get_productCarrierDetailList, params);
}
// 搜索历史
export function getShopSearchHistory(params) {
  return HTTP.get(api.get_shopSearchList, params);
}
//删除搜索历史
export function getClearSearch(params) {
  return HTTP.get(api.getClearSearch, params);
}
// 删除字体
export function SetCollections(params) {
  return HTTP.post(api.postSetCollections, params);
}
//提交设计
export function postProductSaveDesignProduct(params, options) {
  return HTTP.post(api.post_productSaveDesignProduct, params, options);
}

//获取商品详情
export function getProductDetail(params) {
  return HTTP.get(api.get_productDetail, params);
}

//获取元素列表
export function getShopElementsList(params) {
  return HTTP.get(api.get_shopElementsList, params);
}

// 获取元素价格信息

export function getElementPriceInfo(params) {
  return HTTP.get(api.get_elementPriceInfo, params);
}

export function getTemplateInfo(id) {
  return HTTP.get(api.get_getTemplateInfo + id);
}

//上传图片接口
export function postUploadImage(params, options) {
  return HTTP.post(api.post_uploadImage, params, options);
}

/**
 * buddha 接口
 */

export function getElements(params) {
  return HTTP.get("/toopeeks/getElements", params);
}

export function saveToopeeks(params) {
  return HTTP.post("/toopeeks/save", params);
}
/**
 * 获取小程序分享专属码
 */
export function getShareQRcode(params) {
  return HTTP.post("/toopeeks/share", params);
}
export function createdCoupon(params) {
  return HTTP.post("/toopeeks/code", params);
}

export function getMCCustomInfo(id) {
  return HTTP.get(api.get_MCCustomInfo + id);
}

export function getCarrierConfig(params) {
  return HTTP.get(api.get_carrierConfig, params);
}

export function getAvatarDetail(params) {
  return HTTP.get(api.get_AVATAR_DETAIL, params);
}

/**
 * 人脸识别
 * @param {*} id
 * @returns
 */
export function getAIFace(params) {
  return HTTPAI.post(apiAi.post_predict, params, {
    headers: {
      "x-api-key": "C7Inb8gEHVf/3IF8f483SijuWhNW3t7mRMa7PrPlKitcJsSwOAWZVBAxrPPEPEKwyXo245oHcnXVK3J4nndmOqosxh62G2R9yx/oT+KeZ5q5S9DlrRAiIYg3i93hV9+ARRKvKQZWOxwwmqRSDxa/bAGgbgS0nclRTAW+6TubJs=",
    },
  });
}
/**
 *
 * @param {*} params
 * @returns
 */

export function postUploadAvatar(params) {
  return HTTP.post(api.post_UPLOAD_AVATAR, params);
}

/**
 * 获取素材列表
 *
 */

export function getMatterList(params) {
  return HTTP.get(api.get_USER_ELEMENTS, params);
}

/**
 * 获取头像的列表数据
 *
 */
export function getUserAvatarInfo(params) {
  return HTTP.get(api.get_USER_INFO_AVATAR, params);
}

// 获取载体列表数据
export function getCategory({ id = 0, params }) {
  return HTTP.get(`${api.get_CATEGORY_LIST}?pid=${id}`, params);
}
/**
 *
 * @param {*} param0 获取分类数据
 * @returns
 */
export function getCategoryDetail({ id, params }) {
  return HTTP.get(`${api.get_CATEGORY_LIST}?pid=${id}`, params);
}

/**
 *
 * @param {*} param0 获取商品详情
 * @returns
 */
export function getCategoryProductList({ id, params }) {
  return HTTP.get(`${api.get_CATEGORY_PRODUCT_LIST}?cate_id=${id}`, params, {});
}

export function carrierDetailList(data) {
  return HTTP.get(`${api.get_PRODUCT_DETAIL}`, data);
}

// 贴纸系列 目录图片
export function getSeriesList(params) {
  return HTTP.get(`${api.get_SERIES_LIST}`, params);
}

//对应系列详情图片列表
export function getSeriesDetail(params) {
  return HTTP.get(`${api.get_SERIES_DETAIL}`, params);
}

// 获取ip列表数据
export function getIpList(params) {
  return HTTP.get(`${api.get_IP_LIST}`, params);
}

// 关注
export function setUserFocus(params) {
  return HTTP.post(`${api.post_USER_DO_FOCUS}`, params);
}

// 获取ip详情
export function getIpInfo(params) {
  return HTTP.get(`${api.get_IP_INFO}`, params);
}

export function postExportCanvas(params) {
  return HTTP_NODE.post(`${api.post_EXPORT_CANVAS}`, params);
}

//删除头像
export function postDelAvatar(params) {
  return HTTP.post(`${api.post_DEL_AVATAR}`, params);
}
//删除自定义素材
export function postDelUserEle(params) {
  return HTTP.post(`${api.post_DEL_USER_ELE}`, params);
}
//保存素材
export function saveUserElements(params) {
  return HTTP.post(`${api.post_SAVE_USER_ELEMENTS}`, params);
}

export function getCartProductDesignDetail(params) {
  return HTTP.get(`${api.get_CART_PRODUCT_DESIGN_DETAIL}`, params);
}
//product/getDesignTempList
export function getDesignTempList(params) {
  return HTTP.get(`${api.get_DESIGN_TEMP_LIST}`, params);
}
export function getDesignTemPJson(params) {
  return HTTP.get(`${api.get_DESIGN_JSON}`, params);
}

export function bindShareDesignPoster(params) {
  return HTTP.post(`${api.post_BIND_SHARE_DESIGN_POSTER}`, params);
}

export default HTTP;
