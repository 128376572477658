const DEVELOP_URL = `https://dc.tootools.cn/api`;
const PRODUCT_URL = `https://dc.tootools.cn/api`;
const DEVELOP_NODE_URL = `https://alpha.tootools.cn/node`;
const PRODUCT_NODE_URL = `https://mp.tootools.cn/node`;

export const HTTP_REQUEST_URL_AI = "https://uat-tooverse.tootools.cn:3999";

export const HTTP_REQUEST_URL = process.env.VUE_APP_ENV === "production" ? PRODUCT_URL : DEVELOP_URL;
export const HTTP_REQUEST_URL_NODE = process.env.VUE_APP_ENV === "production" ? PRODUCT_NODE_URL : DEVELOP_NODE_URL;

export const api = {
  get_element: `/element/index`,
  get_series: `/series/index`,
  search: `/shopSearch`,
  get_productCarrierDetailList: "/product/carrierDetailList",
  post_productSaveDesignProduct: "/product/saveDesignProduct",
  get_productDetail: "/product/detail/",
  get_shopElementsList: "/element/getShopElementsList",
  get_shopSearchList: "/getShopSearchList",
  post_uploadImage: "/upload/image",
  getClearSearch: "/v2/user/clean_search",
  post_setCollection: "/series/setCollections",
  postSetCollections: "/element/setCollections",
  get_elementPriceInfo: "/element/getElementPriceInfo",
  get_getTemplateInfo: "/product/getSemiCustomTempInfo/",
  get_MCCustomInfo: "/mass/getCustomInfo/",
  get_AIFace: "/toopeeks/getAIFace",
  get_carrierConfig: "/product/getCarrierConfigInfo",
  get_AVATAR_DETAIL: "/toopeeks/detail",
  post_UPLOAD_AVATAR: "/upload",
  get_USER_INFO_AVATAR: "/user/getOwnerInfo",
  get_CATEGORY_LIST: "/getCategory",
  get_CATEGORY_PRODUCT_LIST: "/getCategoryProductList",
  get_PRODUCT_DETAIL: "/product/carrierDetailList",
  get_OSS_TOKEN: "/getOssToken",
  get_SERIES_LIST: "/series/lst",
  get_SERIES_DETAIL: "/series/detail",
  get_IP_LIST: "/user/getShopIpList",
  post_USER_DO_FOCUS: "/user/doFocus",
  get_IP_INFO: "/user/getIpInfo",
  post_EXPORT_CANVAS: "/exportCanvas",
  get_USER_ELEMENTS: "/user/getUserElements",
  post_DEL_AVATAR: "/toopeeks/avatarDel",
  post_DEL_USER_ELE: "/user/deleteUserElements",
  post_SAVE_USER_ELEMENTS: "/user/saveUserElements",
  get_CART_PRODUCT_DESIGN_DETAIL: "/product/getCartProductDesignDetail",
  get_DESIGN_TEMP_LIST: "/product/getDesignTempList",
  get_DESIGN_JSON: "/product/getDesignTempJson",
  post_BIND_SHARE_DESIGN_POSTER: "/product/bindShareDesignPoster",
};

export const apiAi = {
  post_predict: "/tf-model/predict",
};
