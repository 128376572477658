import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bus from "./utils/eventbus";
import enUS from "vant/es/locale/lang/en-US";
import { Toast, Swipe, SwipeItem, Dialog, Image as VanImage, Popup, Tabs, Tab, Slider, Loading, Uploader, Button, Field, Locale } from "vant";
import "vant/lib/index.css";
import "default-passive-events";
import "./assets/css/iconfont";
import "./index.css";
import { userAction } from "@/utils/userAction";
// import { ImgUtil } from "@/utils/imgUtils";
const app = createApp(App);
// ImgUtil.storageImageList();

app.config.globalProperties.$bus = bus;
userAction();
app
  .use(VanImage)
  .use(Dialog)
  .use(SwipeItem)
  .use(Swipe)
  .use(Popup)
  .use(Tabs)
  .use(Tab)
  .use(Toast)
  .use(Slider)
  .use(store)
  .use(router)
  .use(Loading)
  .use(Uploader)
  .use(Button)
  .use(Field)
  .use(Locale)
  .mount("#app");
Locale.use("en-US", enUS);
Toast.setDefaultOptions({ duration: 600 });
