<template>
  <div class="w-full h-full flex justify-center items-center relative editor-container">
    <!-- <div ref="slider" class="keen-slider h-full w-full">
      <EditorCanvas v-for="(item, index) in perspectives" class="keen-slider__slide" :class="'number-slide' + (index + 1)" :key="item.key" :canvasId="item.key" :perspective="item.key" />
    </div> -->

    <van-swipe ref="slider" class="w-full h-full" :touchable="false" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in perspectives" :key="index" class="w-full h-full">
        <EditorCanvas :canvasId="item.key" :perspective="item.key" />
      </van-swipe-item>
    </van-swipe>

    <ToolBar @switchPerspective="switchPerspective" />
    <ColorPicker :colorConfig="colorConfig" />

    <div v-if="!mcId" class="absolute left-1/2 -translate-x-1/2 top-3 flex w-full justify-between px-3">
      <button class="text-xs px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="switchRoute">
        <div class="leading-5">{{ titleView }}</div>
        <img class="object-contain w-4" src="@/static/exchange_series.png" alt="" />
      </button>

      <!-- <button v-if="fromAvatar"
        class="text-xs px-3 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center"
        @click="editAvatar">
        <img class="w-4 h-4" :src="imgIcon" />
        <div class="leading-4">修改头像</div>
      </button> -->
      <button class="text-xs px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="goToSavePreview">
        <div class="leading-5">Finish</div>
        <img class="w-2 rotate-90" src="@/static/arrow_top.png" alt="" />
      </button>
    </div>

    <!-- <div v-else class="absolute left-1/2 -translate-x-1/2 top-3 flex w-full justify-between px-3">
      <button class="text-xs px-3 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="editAvatar">
        <img class="w-4 h-4" :src="imgIcon" />
        <div class="leading-4">修改头像</div>
      </button>
      <button class="text-xs px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="goToSavePreview">
        <div class="leading-5">Finish</div>
        <img class="w-2 rotate-90" src="@/static/arrow_top.png" alt="" />
      </button>
    </div> -->
    <button v-else class="text-xs px-4 py-1 leading-4 rounded-full bg-white absolute right-3 top-3 flex space-x-1 items-center justify-center" @click="goToSavePreview">
      <div class="leading-5">Finish</div>
      <img class="w-2 rotate-90" src="@/static/arrow_top.png" alt="" />
    </button>
    <div class="absolute left-3 bottom-3 flex justify-between">
      <div v-show="$store.getters.getCarrierConfigSize" class="absolute text-[10px] text-[#D6B67D] left-1/2 -translate-x-1/2 bottom-8 w-full text-center">
        Size picked:{{ $store.getters.getCarrierConfigSize }}
      </div>
      <button :class="{ 'active-btn': mcId }" class="text-xs px-4 py-1 leading-4 rounded-full bg-[#D6B67D] flex space-x-1 items-center justify-center" @click="switchCarrier">
        <div class="leading-5 text-whiteMain">change goods ></div>
        <!-- <img class="object-contain w-4" src="@/static/change_carrier_icon.png" alt="" /> -->
      </button>
      <!-- <button v-if="(mcId == null) && (isArtist == false)" class="text-xs px-4 py-1 leading-4 rounded-full bg-white bottom-4 flex space-x-1 items-center justify-center" @click="saveSnapshot">
        <div class="leading-5">保存设计</div>
        <img class="object-contain w-4 h-3" src="@/static/folder.png" alt="" />
      </button> -->
    </div>
  </div>
  <canvas-share class="relative bottom-[-100%] h-0" ref="posterTarget" :showPopupStatus="showPopup" :QRcodeData="QRcodeData"></canvas-share>
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import canvasShare from "@/components/content/canvasShare/index.vue";
import { bindShareDesignPoster } from "@/api/app";
import "keen-slider/keen-slider.min.css";
import b1 from "@/static/buddha_icon.png";
import EditorCanvas from "@/components/EditorCanvas.vue";
import ToolBar from "./ToolBar.vue";
import ColorPicker from "./ColorPicker.vue";
import { Dialog, Toast } from "vant";

export default {
  data() {
    return {
      showPopup: false,
      fromAvatar: false, //是否从头像页面过来
      imgIcon: b1, // icon图片地址
      titleView: "", // 入口名
      isArtist: false, //是否为艺术家
    };
  },
  watch: {
    $route: {
      handler: async function (val) {
        // console.log("watch打印当前的路由", val);
        if (val.path.indexOf("/artist") > -1) {
          this.titleView = "Design Center";
        } else {
          this.titleView = "Artist collection";
        }
        this.fromAvatar = this.$route.query.fromAvatar;
      },
      immediate: true,
      // 深度观察监听
      // deep: true,
    },
  },
  async created() {
    console.log(this.templateId, this.productId, "---------");
    if (this.templateId || this.productId) {
      await this.fetchDesignJSON();
      this.$bus.$emit("loadFromTemplate");
    }

    await this.fetchFontList();
  },
  methods: {
    ...mapMutations(["SET_COLOR_CONFIG", "CHANGE_MENU_STATUS", "CARRIER_SWITCH_STATUS", "SET_SHARE_CANVAS_DATA", "SET_SHARE_POSTER_IMG"]),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig", "fetchDesignJSON", "fetchFontList", "postProductSavePosterActions"]),
    // 路由切换 艺术家 和官方
    switchRoute() {
      let currentRoute = this.$route.path;
      console.log("切换路由", currentRoute);
      Dialog.confirm({
        title: "Attention!",
        message: `Need to redesign if move to${this.titleView}`,
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          //【ID1000216】4.5 创作中心-用户-切换模式
          if (currentRoute.indexOf("/sticker") > -1) {
            this.$router.replace({
              path: "/artist/artist1",
            });
          } else if (currentRoute.indexOf("/artist") > -1) {
            this.$router.replace({
              path: "/sticker",
            });
          }
          window._hmt.push(["_trackEvent", "seriesSwitch", "switchSeries"]);
          setTimeout(() => {
            window.location.reload();
          }, 500);

          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //切换载体
    switchCarrier() {
      if (this.mcId) {
        Dialog.confirm({
          title: "注意!",
          message: `Need to redesign if move to change goods`,
          confirmButtonColor: "#23d59c",
        })
          .then(() => {
            window._hmt.push(["_trackEvent", "carrierButton", "switchCarrier"]);
            this.CARRIER_SWITCH_STATUS();
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      } else {
        // Dialog.confirm({
        //   title: "注意!",
        //   message: `change goods后将无法撤销，是否继续？`,
        //   confirmButtonColor: "#23d59c",
        // })
        //   .then(() => {
        //     this.CARRIER_SWITCH_STATUS();

        //     // on confirm
        //   })
        //   .catch(() => {
        //     // on cancel
        //   });
        this.CARRIER_SWITCH_STATUS();
      }
    },
    getColorList() {
      const colorConfig = { ...this.getColorConfig };
      colorConfig.defaultColor = {
        ...colorConfig.defaultColor,
        ...this.getColorLibrary.filter(item => item.id.toString() === this.carrierConfig.color_id)[0],
      } || {
        color: "",
        id: "",
        type: "",
        title: "",
        isLocked: true,
      };
      this.SET_COLOR_CONFIG(colorConfig);
    },
    async goToSavePreview() {
      console.log(this.carrierConfig?.left && this.carrierConfig?.right, "-------------");

      await new Promise(resolve => {
        setTimeout(() => {
          Toast.loading({
            message: "Generating design...",
            forbidClick: true,
            duration: 0,
          });
          resolve();
        }, 500);
      });

      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        this.$bus.$emit("left:exportPerspectiveCanvas");
        this.$bus.$emit("right:exportPerspectiveCanvas");
        this.$bus.$emit("front:renderPerspectiveCanvas");
        this.$bus.$emit("back:renderPerspectiveCanvas");
      }

      await new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 500);
      });

      this.$bus.$emit(this.$store.state.currentPerspective + ":zoomIn");
      this.$bus.$emit("hideMenuPopup");
      for (let i = 0; i < this.perspectives.length; i++) {
        await new Promise(resolve => {
          this.$bus.$emit(this.perspectives[i].key + ":exportCanvas", resolve);
        });
      }
      // await new Promise((resolve) => {
      //   console.log("front:exportCanvas")
      //   this.$bus.$emit("front:exportCanvas", resolve);
      // });

      // await new Promise((resolve) => {
      //   console.log("back:exportCanvas")
      //   this.$bus.$emit("back:exportCanvas", resolve);
      // });
      // await new Promise((resolve) => {
      //   console.log("left:exportCanvas")
      //   this.$bus.$emit("left:exportCanvas", resolve);
      // });
      // await new Promise((resolve) => {
      //   console.log("right:exportCanvas")
      //   this.$bus.$emit("right:exportCanvas", resolve);
      // });
      // TODO: Finish调用接口 获取制作分享海报
      let res = await this.postProductSavePosterActions(4);
      this.SET_SHARE_CANVAS_DATA(res);
      let posterImg = await this.$refs.posterTarget.initCanvas(res);
      this.SET_SHARE_POSTER_IMG(posterImg);
      bindShareDesignPoster({ product_id: res.product_id, share_poster: posterImg });

      Toast.clear();

      this.$router.push({
        name: "savePreview",
        query: {
          isArtist: this.$route.matched[1].path.indexOf("artist") > -1,
        },
      });
      window._hmt.push(["_trackEvent", "completeButton", "Complete"]);
    },
    editAvatar() {
      Dialog.confirm({
        message: "修改头像后会丢失当前设计，确定继续？",
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          this.$router.back();
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    switchPerspective(e) {
      this.$refs.slider.swipeTo(e);
    },
    saveSnapshot() {
      this.$bus.$emit(this.currentPerspective + ":saveSnapshot");
    },
    // initPerspective() {
    //   const perspectives = ["front", "back", "left", "right"];
    //   perspectives.forEach((item, index) => {
    //     if (!this.carrierConfig[item]) {
    //       return;
    //     }
    //     this.perspectives.push({
    //       index,
    //       key: item,
    //       ...this.carrierConfig[item],
    //     });
    //   });
    // },
  },

  async mounted() {
    await this.fetchColorLibrary();
    await this.fetchCarrierConfig();
    this.getColorList();
    this.fromAvatar = this.$route.query.fromAvatar;
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1;
    // this.$nextTick(() => {
    //   this.slider = new KeenSlider(this.$refs.slider, {
    //     drag: false,
    //     slides: this.perspectives.length,
    //   });
    // });
  },
  activated() {
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1;
  },
  computed: {
    ...mapGetters(["getColorConfig", "getColorId", "getColorLibrary"]),
    ...mapState(["currentPerspective", "carrierConfig", "mcId", "mcConfig", "menuStatus", "templateId", "productId", "designJSON"]),
    perspectives() {
      // 处理每个面的数据,以及该载体拥有哪些面
      const perspectiveKeys = ["front", "back", "left", "right"];
      let perspectiveArr = [];

      perspectiveKeys.forEach((item, index) => {
        if (!this.carrierConfig[item]) {
          return;
        }
        perspectiveArr.push({
          index,
          key: item,
          ...this.carrierConfig[item],
        });
      });

      return perspectiveArr;
    },
  },
  components: {
    canvasShare,
    EditorCanvas,
    ToolBar,
    ColorPicker,
  },
};
</script>

<style>
.active-btn {
  bottom: 32px !important;
}

.editor-container {
  /* background: #f0f0f0; */
}

.editor-grid {
  background: #f0f0f0;
}
</style>
