import * as type from "./mutation-types";
import { getShopElementsList, saveToopeeks, getTemplateInfo, getMCCustomInfo, getCarrierConfig, getCartProductDesignDetail, postProductSaveDesignProduct, uploadOSS } from "@/api/app";
import { dataURLtoBlob } from "@/utils/utils";

export default {
  async fetchColorLibrary({ commit, state }) {
    const { data } = await getShopElementsList({ type: 3 });
    if (data.status === 200) {
      const colorList = data.data.list;
      const colorLibrary = [];
      colorList.forEach(({ value: color, id, type, title, cover_img: coverImg }, index) => {
        colorLibrary[index] = {
          color,
          id,
          type,
          title,
          coverImg,
        };
      });
      const colorConfig = { ...state.colorConfig };

      Object.keys(colorConfig).forEach((key, index) => {
        if (key === "defaultColor") return;
        colorConfig[key] = colorLibrary[index - 1];
      });

      commit(type.SET_COLOR_CONFIG, { ...state.colorConfig, ...colorConfig });

      commit(type.SET_COLOR_LIBRARY, colorLibrary);
    }
  },
  async fetchFontList({ commit, state }) {
    const { data } = await getShopElementsList({ type: 2 });
    if (data.status === 200) {
      const fontList = data.data.list[0].elements;
      commit(type.SET_FONT_LIST, fontList);
    }
  },
  async saveDesign({ commit, state }, products) {
    let options = state.designOptions;
    return new Promise((reject, resolve) => {
      saveToopeeks(options).then(res => {
        resolve(res);
      });
    });
  },
  async fetchDesignJSON({ commit, state }) {
    if (state.templateId) {
      const { data } = await getTemplateInfo(state.templateId);
      if (data.status === 200) {
        commit(type.SET_DESIGN_JSON, data.data.design_json);
      }
    }
    if (state.productId) {
      const { data } = await getCartProductDesignDetail({ product_id: state.productId });
      console.log("getCartProductDesignDetail", data);
      if (data.status === 200) {
        commit(type.SET_CURRENT_IP_ID, data.data.ip_id);
        commit(type.SET_DESIGN_JSON, data.data.design_json);
      }
    }
  },
  async fetchMCConfig({ commit, state }) {
    if (state.mcId) {
      const { data } = await getMCCustomInfo(state.mcId || 1);
      if (data.status === 200) {
        const mcConfig = data.data;
        commit(type.SET_MC_CONFIG, mcConfig);
      }
    }
  },
  async fetchCarrierConfig({ commit, state }) {
    const params = {
      carrier_id: state.carrier_id,
      unique: state.unique,
    };
    const { data } = await getCarrierConfig(params);
    if (data.status === 200) {
      const carrierConfig = data.data;
      if (carrierConfig.left == null) {
        delete carrierConfig.left;
      }
      if (carrierConfig.right == null) {
        delete carrierConfig.right;
      }
      if (carrierConfig.back == null) {
        delete carrierConfig.back;
      }
      commit(type.SET_CARRIER_CONFIG, carrierConfig);
    }
  },
  async postProductSavePosterActions({ commit, dispatch, state, getters }, saveType) {
    //saveType 2 直接购买  1 Add to shopping cart
    // this.isLoading = true;

    const { carrierId, storeName, containerImage, colorImage, size, price, productId } = getters.getItemInfo;
    let perspective = {
      front: {},
      back: {},
      left: {},
      right: {},
    };
    // const perspectiveName = {
    //   front: "正面",
    //   back: "背面",
    //   left: "左面",
    //   right: "右面",
    // };

    let elementIds = [];
    Object.keys(state.elementIds).forEach(key => {
      elementIds = [...elementIds, ...state.elementIds[key].split(",")];
    });
    for (let key in state.designJSONs) {
      perspective[key].design_json = state.designJSONs[key];
    }
    for (let key in state.outputPreviewImages) {
      if (state.outputPreviewImages[key] == null) {
        // Toast.clear();
        continue;
      }

      const outputPreviewImage = await dispatch("uploadImage", state.outputPreviewImages[key]);
      if (!outputPreviewImage) {
        return false;
      }
      perspective[key].production_img = outputPreviewImage;
    }

    for (let key in state.outputCanvasImages) {
      try {
        if (state.outputPreviewImages[key] === null) {
          continue;
        }
        if (state.outputCanvasImages[key] === null) {
          continue;
        }
        const outputCanvasImage = await dispatch("uploadImage", state.outputCanvasImages[key]);
        if (!outputCanvasImage) {
          console.error("上传图片超时");
          return false;
        }
        perspective[key].production_thumb_img = outputCanvasImage;
      } catch (error) {
        console.log(state.outputCanvasImages[key]);
        console.error(error);
        return;
      }
    }

    const params = {
      carrier_id: carrierId,
      unique: state.unique,
      ip_id: state.ipIds.front,
      element_ids: elementIds.filter(i => i).join(","),
      store_name: state.carrierConfig.store_name,
      image: state.carrierConfig.image,
      id: state.shareDesignPosterData.product_id || "0",
      price: state.carrierConfig.price,
      saveType: saveType,
      channel: state.channel,
      ...perspective,
    };

    if (state.avatarId) {
      params.toopeeks_id = state.avatarId;
    }

    const { data } = await postProductSaveDesignProduct(params);

    if (data.status === 200) {
      commit(type.SET_PRODUCT_ID, data.data.product_id);
      console.log("data.data", data.data);

      return data.data;
      // return true;
    } else {
      console.error(data.msg);
    }
  },
  async uploadImage(context, dataUrl) {
    const fileBlob = dataURLtoBlob(dataUrl);

    const res = await uploadOSS(fileBlob);
    console.log(res, res.options.endpoint + "/" + res.result.name);
    return res.options.endpoint + "/" + res.result.name;
  },
};
