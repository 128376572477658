<template>
  <div class="relative">
    <!-- 展示的内容 -->
    <div ref="posterTarget" :style="{ width: `${width}px`, height: `${height}px` }" class="relative w-full h-full poster-container">
      <div ref="posterCanvas" class="fabric-canvas w-full absolute top-1/2 -translate-y-1/2">
        <canvas id="Canvas"></canvas>
      </div>
    </div>
    <!-- 弹出的海报 -->
    <vanPopup class="vanPopup_class" :style="{ width: `${width}px`, height: `${height}px` }" :custom-style="{ background: '#272727' }" v-model:show="isShowPopup">
      <van-loading class="z-40 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" vertical v-show="isShowLoading" color="#3F3F3F">Loading...</van-loading>
      <div class="w-full absolute top-1/3 -translate-y-1/2 z-10">
        <img class="w-full" :src="previewImg" alt="" />
      </div>
      <div class="absolute z-[9999] w-full items-center text-xs flex justify-center bottom-[-40px]">
        <i class="iconfont icon-download text-sm mr-1" style="color: #ffffff" /> <span class="text-whiteMain">Long press the image to save local</span>
      </div>
    </vanPopup>
  </div>
</template>

<script>
import posterBg from "@/static/share_poster.png";
import { uploadOSS } from "@/api/app";
import { dataURLtoBlob } from "@/utils/utils";
import { mapState } from "vuex";
import { Popup } from "vant";
import { fabric } from "fabric";

const ratio = 651 / 383;

export default {
  data() {
    return {
      isShowLoading: true,
      width: 300,
      height: 300 * ratio,
      ratio,
      scale: 300 / 383,
      previewImg: null,
    };
  },
  components: { vanPopup: Popup },
  props: {
    showPopupStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["outputPreviewImages"]),
    isShowPopup: {
      get() {
        return this.showPopupStatus;
      },
      set(value) {
        this.$emit("changeShow", value);
      },
    },
  },
  methods: {
    async uploadImage(dataUrl) {
      const fileBlob = dataURLtoBlob(dataUrl);
      const res = await uploadOSS(fileBlob);
      console.log(res, res.options.endpoint + "/" + res.result.name);
      return res.options.endpoint + "/" + res.result.name;
    },
    async initCanvas(QRcodeData) {
      this.canvas = new fabric.Canvas("posterCanvas");
      this.resizeCanvas();
      await this.addBg(QRcodeData);
      await this.addShareInfo(QRcodeData);
      await this.addFontDesign(QRcodeData);
      this.canvas.renderAll();
      let outputFrontDesignImg = await this.exportCanvas();
      return outputFrontDesignImg;
    },
    addImage(url, { left, top, width, height, originX, originY }) {
      return new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          img => {
            img.set({
              left,
              top,
              rx: 10,
              ry: 10,
              originX: originX || "center",
              originY: originY || "center",
              scaleX: (width / img.width).toFixed(2),
              scaleY: (height / img.height).toFixed(2),
              backgroundColor: "transparent",
              // selectable: false
            });
            this.canvas.add(img);
            resolve(img);
          },
          { crossOrigin: "Anonymous" },
        ),
      );
    },
    addRect({ left, top, width, height, originX, originY }) {
      const rect = new fabric.Rect({
        left,
        top,
        width,
        height,
        rx: 10,
        ry: 10,
        originX: originX || "center",
        originY: originY || "center",
        fill: "white",
        selectable: false,
        absolutePositioned: true,
      });
      this.canvas.add(rect);
    },

    resizeCanvas() {
      // console.log({ width: this.width });
      this.canvas.setDimensions({
        width: this.width,
        height: this.height,
      });
      this.canvas.renderAll();
    },
    async addBg(QRcodeData) {
      const left = this.canvas.getCenter().left;
      const top = this.canvas.getCenter().top;
      const width = this.width + 2;
      const height = this.height + 2;
      const image = await this.addImage(QRcodeData.poster_bg_img, { left, top, width, height });
      this.canvas.renderAll();
    },
    async addShareInfo(QRcodeData) {
      const width = 64 * this.scale;
      const height = 64 * this.scale;
      const left = 28 * this.scale;
      const top = this.height - 20 * this.scale;
      this.addQrCodeBound();
      await this.addImage(QRcodeData.qrcode, {
        left,
        top,
        width,
        height,
        originX: "left",
        originY: "bottom",
      });
    },
    addQrCodeBound() {
      const width = 80 * this.scale;
      const height = 80 * this.scale;
      const left = 20 * this.scale;
      const top = this.height - 12 * this.scale;
      this.addRect({
        left,
        top,
        width,
        height,
        originX: "left",
        originY: "bottom",
      });
    },

    async addFontDesign(QRcodeData) {
      const { left, top } = this.canvas.getCenter();
      const width = 400 * this.scale;
      const height = 400 * this.scale;

      const img = await this.addImage(this.outputPreviewImages.front, {
        left,
        top,
        width,
        height,
        originX: "center",
        originY: "center",
        clipPath: true,
      });
    },

    exportCanvas() {
      return new Promise((resolve, reject) => {
        this.previewImg = this.canvas.toDataURL({
          multiplier: 1600 / this.canvas.width,
          format: "png",
          crossOrigin: "anonymous",
        });
        let img = this.uploadImage(this.previewImg);
        this.isShowLoading = false;
        resolve(img);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.poster-container {
  background-color: #fff;
  height: 379px;
  width: 223px;
}
// .vanPopup_class {
//   .van-overlay {
//     overflow-y: unset !important;
//   }
// }
</style>
