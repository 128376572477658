<template>
  <div class="container">
    <div :class="[{ show: visible }]">
      <div v-if="showMask" @click.stop="clickMask" class="mask"></div>
      <div :style="{ height: heightStyle }" class="popover">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popover",
  props: {
    checked: Boolean,
    heightStyle: {
      type: String,
      default() {
        return "280px";
      },
    },
    showMask: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      // args: Array.from({length:10}, (v,k) => k)
    };
  },
  computed: {
    visible: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    clickMask() {
      this.$emit("closeMask");
    },
  },
};
</script>

<style lang="scss" scoped>
// .show {
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
// }
.show .mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.popover {
  z-index: 1000;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: #ffffff;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
}
.show .popover {
  z-index: 1000;
  opacity: 1;
  transform: translateY(0);
}
</style>
