export default {
  // getters.js
  // Get the list of patterns
  getPatternList: state => state.patternList,
  // Get the list of fonts
  getFontList: state => state.fontList,
  getColorId: state => state.carrierConfig.color_id,
  getImage: state => state.image,
  getColorLibrary: state => state.colorLibrary,
  getColorConfig: state => state.colorConfig,
  getItemInfo: state => ({
    productId: state.productId,
    carrierId: state.carrier_id,
    storeName: state.store_name,
    price: state.price,
    containerImage: state.image,
    colorImage: state.value1,
    size: state.value2,
  }),
  getMenuStatus: state => state.menuStatus,
  getSliderVal: state => state.sliderVal,
  getCarrierParams: state => ({
    x1: state.x1,
    y1: state.y1,
    x2: state.x2,
    y2: state.y2,
    carrierWidth: state.carrierWidth,
  }),
  getAvatarPartMap: state => state.avatarPartMap,
  getAvatarSvg: state => state.avatarSvg,
  getAvatarColor: state => state.avatarColor,
  getCanvasObjects: state => state.canvasObjects,
  getOutputCanvasImage: state => state.outputCanvasImage,
  getOutputPreviewImage: state => state.outputPreviewImage,
  getAvatarElementIds: state => state.avatarElementIds,
  getSleeveImages: state => ({ leftSleeveImage: state.leftSleeveImage, rightSleeveImage: state.rightSleeveImage }),
  getOutputAvatarImage: state => {
    return new Promise(resolve => {
      resolve(state.outputAvatarImage);
    });
  },
  getAvatarType: state => state.avatarType,
  getIsChild: state => state.isChid,
  getAddChannel: state => state.addChannel,
  getCarrierSize: state => state.carrierSize,
  //获取请求中的返回的载体大小
  getCarrierConfigSize: state => state.carrierConfig.size,
  // getElement: state => state.elementIds
};
