<template>
  <div class="container">
    <div v-for="itema in dataList" :key="itema.id">
      <div v-if="itema.searchType === 1 || itema.searchType === 4" class="card-container">
        <div class="content flex justify-between">
          <div class="name">
            {{ itema.title }}
          </div>
          <div class="">
            <img @click="collect(0, itema)" v-if="itema.seriesIsCollection" src="@/static/collected_icon.png" class="eries-icon-1" />
            <img @click="collect(1, itema)" v-else src="@/static/collect_icon.png" class="eries-icon-1" />
          </div>
        </div>
        <div class="main_content flex">
          <div class="left-mian-img">
            <img class="main-img" :src="itema.cover_img" alt="" />
          </div>
          <div class="right-content">
            <div class="right-img" @click="checkedItem(item)" v-for="item in itema.elements" :key="item.id">
              <tags-item :imgValue="item.value"></tags-item>
            </div>
          </div>
        </div>
      </div>
      <div v-if="itema.searchType === 2">
        <font-item :fontList="itema"></font-item>
      </div>
    </div>
  </div>
</template>

<script>
// import elementItem from "./elementItem.vue";
import tagsItem from "@/components/content/tagsItem/tagsItem.vue";
import fontItem from "@/components/content/elementInfoList/fontItem.vue";
import { setSeriesCollect } from "@/api/app.js";
import { Toast } from "vant";
export default {
  components: {
    // elementItem,
    tagsItem,
    fontItem,
  },
  props: {
    dataList: {
      type: Array,
      require: true,
    },
  },
  methods: {
    //点击图片映射到canvas
    checkedItem(item) {
      let url = item.value;
      let options = {
        value1: item.value1,
        value2: item.value2,
        value3: item.value3,
        elementId: item.id,
        ipId: item.ip_id || 0,
        type: item.type,
      };
      this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });
    },
    //点击收藏
    collect(status, item) {
      setSeriesCollect({
        series_id: item.id,
        status,
      }).then(res => {
        if (res.status == 200) {
          if (status == 1) {
            Toast("收藏成功");
          }
          item.seriesIsCollection = item.seriesIsCollection ? 0 : 1;
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .card-container {
    width: 100%;
    box-sizing: border-box;
    .content {
      width: 100%;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      .eries-icon-1 {
        width: 18px;
        height: 20px;
      }
    }
    .main_content {
      margin-top: 16px;
      margin-bottom: 24px;
      padding: 0 16px;
      display: flex;
      width: 100%;
      box-sizing: border-box;

      .left-mian-img {
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        width: 80px;
        border-radius: 6px;

        .main-img {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          background-color: #f5f5f5;
        }
      }

      .right-content {
        display: grid;
        grid-template-columns: repeat(3, 80px);
        grid-template-rows: repeat(atuo-fill, 80px);
        grid-auto-rows: 80px;
        gap: 8px 8px;

        .right-img {
          border-radius: 6px;
          background-color: #f5f5f5;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
</style>
